<script setup>
import { ref, watch, onMounted } from 'vue';
import { TrashIcon } from "@heroicons/vue/20/solid";
import axios from 'axios';

// Reactive data for input and lists
const searchQuery = ref('');
const mm = ref([]);
const mh = ref([]);
const selectedSetting = ref('MH');  // Default to 'MH'
const selectedMember = ref(null);  // Store the selected member data for editing or creating
const selectedMemberType = ref(null); // 'MM' or 'MH'
const creatingNew = ref(false); // Flag to determine if we're creating a new member

// Settings for MM and MH
const settings = [
  { name: 'MH', description: 'LES DÉCORÉS MÉDAILLE D\'HONNEUR' },
  { name: 'MM', description: 'LES DÉCORÉS MÉRITE MARITIME' },
];

const cancelEdit = () => {
  selectedMember.value = null;
  selectedMemberType.value = null;
  creatingNew.value = false;
};

// Method to call the API
const fetchData = async () => {
  const queryParams = {
    name: searchQuery.value,
    type: selectedSetting.value.toLowerCase(),
  };
  
  try {
    const response = await axios.post('https://api.meritemaritime-fnmm.com/medailler/search', queryParams);
    if (selectedSetting.value === 'MM') {
      mm.value = response.data;
    } else if (selectedSetting.value === 'MH') {
      mh.value = response.data;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

// Watch for searchQuery changes
watch(searchQuery, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    fetchData();
  }
}, { debounce: 500 });

// Watch for setting changes
watch(selectedSetting, () => {
  fetchData();
});

// Initial fetch when component is mounted
onMounted(() => {
  fetchData();
});

// Edit item logic
const editItem = (type, member) => {
  selectedMemberType.value = type;
  selectedMember.value = { ...member };
  creatingNew.value = false;  // Ensure we're editing, not creating
};

// Handle creating new member
const createNewMember = () => {
  selectedMemberType.value = selectedSetting.value;
  selectedMember.value = {};  // Empty object to create new member
  creatingNew.value = true;  // Flag to show the create form
};

// Séparer les méthodes de sauvegarde pour MM et MH
const saveMemberMM = async () => {
  try {
    const response = await axios.post('https://api.meritemaritime-fnmm.com/decore/create/mm', selectedMember.value);
    console.log('Membre MM créé avec succès:', response.data);
    fetchData();
    cancelEdit();
  } catch (error) {
    console.error('Erreur lors de la création du membre MM:', error);
  }
};

const saveMemberMH = async () => {
  try {
    const response = await axios.post('https://api.meritemaritime-fnmm.com/decore/create/mh', selectedMember.value);
    console.log('Membre MH créé avec succès:', response.data);
    fetchData();
    cancelEdit();
  } catch (error) {
    console.error('Erreur lors de la création du membre MH:', error);
  }
};

// Méthodes de modification pour MM et MH
const updateMemberMM = async () => {
  try {
    const response = await axios.put('https://api.meritemaritime-fnmm.com/decore/update/mm', selectedMember.value);
    console.log('Membre MM modifié avec succès:', response.data);
    fetchData();
    cancelEdit();
  } catch (error) {
    console.error('Erreur lors de la modification du membre MM:', error);
  }
};

const updateMemberMH = async () => {
  try {
    const response = await axios.put('https://api.meritemaritime-fnmm.com/decore/update/mh', selectedMember.value);
    console.log('Membre MH modifié avec succès:', response.data);
    fetchData();
    cancelEdit();
  } catch (error) {
    console.error('Erreur lors de la modification du membre MH:', error);
  }
};

// Ajouter les méthodes de suppression
const deleteMemberMM = async (id) => {
  try {
    const response = await axios.delete(`https://api.meritemaritime-fnmm.com/decore/delete/mm/${id}`);
    console.log('Membre MM supprimé avec succès:', response.data);
    fetchData();
  } catch (error) {
    console.error('Erreur lors de la suppression du membre MM:', error);
  }
};

const deleteMemberMH = async (id) => {
  try {
    const response = await axios.delete(`https://api.meritemaritime-fnmm.com/decore/delete/mh/${id}`);
    console.log('Membre MH supprimé avec succès:', response.data);
    fetchData();
  } catch (error) {
    console.error('Erreur lors de la suppression du membre MH:', error);
  }
};
</script>


<template>
  <div class="bg-gray-900 p-6 rounded-xl shadow mt-20 border-gray-700 border">
    <div class="col-span-full mb-6">
      <div>
        <input 
          type="text" 
          v-model="searchQuery"
          autocomplete="search" 
          placeholder="Rechercher un nom" 
          class="px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" 
        />
      </div>
    </div>

    

    <!-- Settings for MH and MM -->
    <fieldset aria-label="Privacy setting" class="-space-y-px rounded-md bg-gray-800 mb-6">
      <label v-for="setting in settings" :key="setting.name" :value="setting.name" :aria-label="setting.name" :aria-description="setting.description" class="group flex cursor-pointer border border-gray-500 p-4 first:rounded-tl-md first:rounded-tr-md last:rounded-bl-md last:rounded-br-md focus:outline-none">
        <input 
          name="privacy-setting" 
          v-model="selectedSetting" 
          :value="setting.name" 
          type="radio" 
          class="relative mt-0.5 size-4 shrink-0 appearance-none rounded-full border border-gray-300 bg-gray-700 checked:border-green-500 checked:ring-2 checked:ring-green-500" 
        />
        <span class="ml-3 flex flex-col">
          <span class="block text-sm font-medium text-gray-400">{{ setting.name }}</span>
          <span class="block text-sm text-gray-600">{{ setting.description }}</span>
        </span>
      </label>
    </fieldset>

    <button 
      type="button" 
      @click="createNewMember" 
      class="rounded-md mb-6 flex w-full justify-center bg-green-600 px-3 py-4 text-sm font-semibold text-white shadow-sm hover:bg-green-500">
      Créer
    </button>

    <!-- Formulaires de création -->
    <div v-if="creatingNew && selectedMemberType === 'MM'" class="mt-8 p-6 bg-gray-800 rounded-md shadow">
      <h2 class="text-xl font-semibold text-white">Créer un membre (MM)</h2>
      <form @submit.prevent="saveMemberMM">
        <div class="mb-4">
          <label for="name" class="block text-gray-300">Nom</label>
          <input v-model="selectedMember.NOM" id="name" type="text" class="mt-2 block w-full px-4 py-2 rounded-md text-gray-900" />
        </div>
        <div class="mb-4">
          <label for="chevalier" class="block text-gray-300">Chevalier</label>
          <input v-model="selectedMember.Chevalier" id="chevalier" type="text" class="mt-2 block w-full px-4 py-2 rounded-md text-gray-900" />
        </div>
        <div class="mb-4">
          <label for="officier" class="block text-gray-300">Officier</label>
          <input v-model="selectedMember.Officier" id="officier" type="text" class="mt-2 block w-full px-4 py-2 rounded-md text-gray-900" />
        </div>
        <div class="mb-4">
          <label for="commander" class="block text-gray-300">Commandeur</label>
          <input v-model="selectedMember.Commandeur" id="commander" type="text" class="mt-2 block w-full px-4 py-2 rounded-md text-gray-900" />
        </div>
        <div class="mb-4">
          <label for="autres" class="block text-gray-300">Autres</label>
          <input v-model="selectedMember.Autres" id="autres" type="text" class="mt-2 block w-full px-4 py-2 rounded-md text-gray-900" />
        </div>
        <div class="mt-6 flex gap-4">
          <button type="submit" class="rounded-md bg-blue-600 px-4 py-2 text-white">Sauvegarder</button>
          <button type="button" @click="cancelEdit" class="rounded-md bg-gray-600 px-4 py-2 text-white">Annuler</button>
        </div>
      </form>
    </div>

    <div v-if="creatingNew && selectedMemberType === 'MH'" class="mt-8 p-6 bg-gray-800 rounded-md shadow">
      <h2 class="text-xl font-semibold text-white">Créer un membre (MH)</h2>
      <form @submit.prevent="saveMemberMH">
        <div class="mb-4">
          <label for="prenom" class="block text-gray-300">Prénom</label>
          <input v-model="selectedMember.Prenom" id="prenom" type="text" class="mt-2 block w-full px-4 py-2 rounded-md text-gray-900" />
        </div>
        <div class="mb-4">
          <label for="annee" class="block text-gray-300">Année</label>
          <input v-model="selectedMember.Annee" id="annee" type="text" class="mt-2 block w-full px-4 py-2 rounded-md text-gray-900" />
        </div>
        <div class="mb-4">
          <label for="quartier" class="block text-gray-300">Quartier</label>
          <input v-model="selectedMember.Quartier" id="quartier" type="text" class="mt-2 block w-full px-4 py-2 rounded-md text-gray-900" />
        </div>
        <div class="mb-4">
          <label for="fonction" class="block text-gray-300">Fonction</label>
          <input v-model="selectedMember.Fonction" id="fonction" type="text" class="mt-2 block w-full px-4 py-2 rounded-md text-gray-900" />
        </div>
        <div class="mt-6 flex gap-4">
          <button type="submit" class="rounded-md bg-blue-600 px-4 py-2 text-white">Sauvegarder</button>
          <button type="button" @click="cancelEdit" class="rounded-md bg-gray-600 px-4 py-2 text-white">Annuler</button>
        </div>
      </form>
    </div>

    <!-- Formulaires d'édition -->
    <div v-if="selectedMember && !creatingNew && selectedMemberType === 'MM'" class="mt-8 p-6 bg-gray-800 rounded-md shadow">
      <h2 class="text-xl font-semibold text-white">Modifier le membre (MM)</h2>
      <form @submit.prevent="updateMemberMM">
        <div class="mb-4">
          <label for="name" class="block text-gray-300">Nom</label>
          <input v-model="selectedMember.NOM" id="name" type="text" class="mt-2 block w-full px-4 py-2 rounded-md text-gray-900" />
        </div>
        <div class="mb-4">
          <label for="chevalier" class="block text-gray-300">Chevalier</label>
          <input v-model="selectedMember.Chevalier" id="chevalier" type="text" class="mt-2 block w-full px-4 py-2 rounded-md text-gray-900" />
        </div>
        <div class="mb-4">
          <label for="officier" class="block text-gray-300">Officier</label>
          <input v-model="selectedMember.Officier" id="officier" type="text" class="mt-2 block w-full px-4 py-2 rounded-md text-gray-900" />
        </div>
        <div class="mb-4">
          <label for="commander" class="block text-gray-300">Commandeur</label>
          <input v-model="selectedMember.Commandeur" id="commander" type="text" class="mt-2 block w-full px-4 py-2 rounded-md text-gray-900" />
        </div>
        <div class="mb-4">
          <label for="autres" class="block text-gray-300">Autres</label>
          <input v-model="selectedMember.Autres" id="autres" type="text" class="mt-2 block w-full px-4 py-2 rounded-md text-gray-900" />
        </div>
        <div class="mt-6 flex gap-4">
          <button type="submit" class="rounded-md bg-blue-600 px-4 py-2 text-white">Sauvegarder</button>
          <button type="button" @click="cancelEdit" class="rounded-md bg-gray-600 px-4 py-2 text-white">Annuler</button>
        </div>
      </form>
    </div>

    <div v-if="selectedMember && !creatingNew && selectedMemberType === 'MH'" class="mt-8 p-6 bg-gray-800 rounded-md shadow">
      <h2 class="text-xl font-semibold text-white">Modifier le membre (MH)</h2>
      <form @submit.prevent="updateMemberMH">
        <div class="mb-4">
          <label for="prenom" class="block text-gray-300">Prénom</label>
          <input v-model="selectedMember.Prenom" id="prenom" type="text" class="mt-2 block w-full px-4 py-2 rounded-md text-gray-900" />
        </div>
        <div class="mb-4">
          <label for="annee" class="block text-gray-300">Année</label>
          <input v-model="selectedMember.Annee" id="annee" type="text" class="mt-2 block w-full px-4 py-2 rounded-md text-gray-900" />
        </div>
        <div class="mb-4">
          <label for="quartier" class="block text-gray-300">Quartier</label>
          <input v-model="selectedMember.Quartier" id="quartier" type="text" class="mt-2 block w-full px-4 py-2 rounded-md text-gray-900" />
        </div>
        <div class="mb-4">
          <label for="fonction" class="block text-gray-300">Fonction</label>
          <input v-model="selectedMember.Fonction" id="fonction" type="text" class="mt-2 block w-full px-4 py-2 rounded-md text-gray-900" />
        </div>
        <div class="mt-6 flex gap-4">
          <button type="submit" class="rounded-md bg-blue-600 px-4 py-2 text-white">Sauvegarder</button>
          <button type="button" @click="cancelEdit" class="rounded-md bg-gray-600 px-4 py-2 text-white">Annuler</button>
        </div>
      </form>
    </div>

    <h1 class="text-gray-400 mb-6">Résultat</h1>

    <!-- MM LIST -->
    <div v-if="selectedSetting === 'MM'" class="mx-auto grid max-w-7xl grid-cols-1 gap-12 xl:grid-cols-1">
      <ul role="list" class="mx-auto w-full grid max-w-4xl grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-8 xl:col-span-2">
        <li v-for="mmItem in mm" :key="mmItem.id" class="border border-gray-500 p-2 rounded shadow bg-gray-700">
          <h3 class="text-lg/8 font-semibold text-gray-200">{{ mmItem.NOM }}</h3>
          <div class="grid lg:grid-cols-4 grid-cols-3">
            <div class="flex flex-col">
              <h4 class="text-gray-300">Chevalier</h4>
              <p class="text-base/7 text-gray-200">{{ mmItem.Chevalier }}</p>
            </div>
            <div class="flex flex-col">
              <h4 class="text-gray-300">Officier</h4>
              <p class="text-base/7 text-gray-200">{{ mmItem.Officier }}</p>
            </div>
            <div class="flex flex-col">
              <h4 class="text-gray-300">Commandeur</h4>
              <p class="text-base/7 text-gray-200">{{ mmItem.Commandeur }}</p>
            </div>
            <div class="flex flex-col">
              <h4 class="text-gray-300">Autres</h4>
              <p class="text-base/7 text-gray-200">{{ mmItem.Autres }}</p>
            </div>
          </div>
          <div class="mt-6 flex items-center justify-end gap-x-4">
            <button type="button" @click="editItem('MM', mmItem)" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm">Modifier</button>
            <button type="button" @click="deleteMemberMM(mmItem.id)" class="rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500">
              <TrashIcon class="w-5 h-5 shrink-0 text-gray-200 hover:text-red-400" aria-hidden="true"/>
            </button>
          </div>
        </li>
      </ul>
    </div>

    <!-- MH LIST -->
    <div v-if="selectedSetting === 'MH'" class="mx-auto grid max-w-7xl grid-cols-1 gap-12 xl:grid-cols-1">
      <ul role="list" class="mx-auto w-full grid max-w-4xl grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-8 xl:col-span-2">
        <li v-for="mhItem in mh" :key="mhItem.id" class="border border-gray-500 p-2 rounded bg-gray-700 shadow">
          <h3 class="text-lg/8 font-semibold text-gray-200">{{ mhItem.Prenom }}</h3>
          <div class="grid sm:grid-cols-3 grid-cols-2">
            <div class="flex flex-col">
              <h4 class="text-gray-300">Année</h4>
              <p class="text-base/7 text-gray-200">{{ mhItem.Annee }}</p>
            </div>
            <div class="flex flex-col">
              <h4 class="text-gray-300">Quartier</h4>
              <p class="text-base/7 text-gray-200">{{ mhItem.Quartier }}</p>
            </div>
            <div class="flex flex-col">
              <h4 class="text-gray-300">Fonction</h4>
              <p class="text-base/7 text-gray-200">{{ mhItem.Fonction }}</p>
            </div>
          </div>
          <div class="mt-6 flex items-center justify-end gap-x-4">
            <button type="button" @click="editItem('MH', mhItem)" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm">Modifier</button>
            <button type="button" @click="deleteMemberMH(mhItem.id)" class="rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500">
              <TrashIcon class="w-5 h-5 shrink-0 text-gray-200 hover:text-red-400" aria-hidden="true"/>
            </button>
          </div>
        </li>
      </ul>
    </div>

 
  </div>
</template>