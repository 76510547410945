<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'  // Importation de useRouter pour la redirection
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/vue/24/outline'

import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import TabVue from "@/components/Backoffice/TableauDeBord/TabVue.vue";
import MesVue from "@/components/Backoffice/Messagerie/MesVue.vue";
import EspaceVue from "@/components/Backoffice/Espace/EspaceVue.vue";
import FedeVue from "@/components/Backoffice/GestionDesArticles/FNMM/FedeVue.vue";
import SecVue from "@/components/Backoffice/GestionDesArticles/Sections/SecVue.vue";
import GalerieVue from "@/components/Backoffice/GaleriePhotos/GalerieVue.vue";
import DocumentVue from "@/components/Backoffice/DocumentsDuMois/Document.vue";

import DecoVue from "@/components/Backoffice/Decores/DecoVue.vue";
import PrixVue from "@/components/Backoffice/Prix/PrixVue.vue";
import CarVue from "@/components/Backoffice/Carrousel/CarVue.vue";
import OrdreVue from "@/components/Backoffice/Ordre/OrdreVue.vue";
import SecEditVue from "@/components/Backoffice/Sections/SecEditVue.vue";
import DecorationsVue from "@/components/Backoffice/Decorations/DecorationsVue.vue";

// Ton code de navigation
const navigation = ref([
  { name: 'Tableau de bord', href: '#', current: true, component: TabVue },
  { name: 'Boîte de réception', href: '#', current: false, component: MesVue },
  {
    name: 'Outils',
    current: false,
    children: [
      { name: 'Carrousel', href: '#', component: CarVue, current: false },
      { name: 'Galerie d’images', href: '#', component: GalerieVue, current: false },
      { name: 'Documents du mois', href: '#', component: DocumentVue, current: false },
    ],
  },
  {
    name: 'Gestion des membres',
    current: false,
    children: [
      { name: 'Espace membre', href: '#', current: false, component: EspaceVue },
    ],
  },
  {
    name: 'Gestion des articles',
    current: false,
    children: [
      { name: 'Fédération', href: '#', component: FedeVue, current: false },
      { name: 'Sections', href: '#', component: SecVue, current: false},
    ],
  },
  {
    name: 'Navigations',
    current: false,
    children: [
      { name: 'Décorés', href: '#', component: DecoVue, current: false },
      { name: 'Décorations', href: '#', component: DecorationsVue, current: false },
      { name: 'Sections', href: '#', component: SecEditVue,current: false },
      { name: 'Conseil de l\'Ordre', href: '#', component: OrdreVue, current: false },
      { name: 'Prix littéraires', href: '#', component: PrixVue, current: false },
    ],
  },
]);

const sidebarOpen = ref(false)
const activeView = ref(TabVue);

function selectView(item) {
  navigation.value.forEach(nav => {
    nav.current = false;
    if (nav.children) {
      nav.children.forEach(child => (child.current = false));
    }
  });

  if (item.component) {
    activeView.value = item.component;
  }

  item.current = true;
}

// Logique de vérification du token
const router = useRouter();

onMounted(async () => {
  const token = localStorage.getItem('authToken');

  if (token) {
    try {
      const response = await fetch('https://api.meritemaritime-fnmm.com/auth/check', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: token }),
      });

      const data = await response.json();

      if (data.status !== 'hbc_checker_yes') {
        router.push('/');  // Redirection si la réponse n'est pas "hbc_checker_yes"
      }
    } catch (error) {
      console.error("Erreur lors de la vérification du token:", error);
      router.push('/');  // Redirection en cas d'erreur
    }
  } else {
    router.push('/');  // Redirection si aucun token n'est trouvé
  }
});
</script>


<template>
  <div class="bg-[#1F2937] min-h-dvh">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog class="relative z-50 lg:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="size-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-700 bg-[#111827] px-6">
                <div class="flex h-16 shrink-0 items-center">
                  <img class="h-12 mt-6 rounded-xl shadow w-auto" src="@/assets/Logos/logo.png"/>
                </div>
                <nav class="flex flex-1 flex-col">
                  <ul role="list" class="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" class="-mx-2 space-y-1">
                        <a href="/" class="text-[#9CA3AF] hover:bg-[#1F2937] hover:text-white block rounded-md py-2 pl-10 pr-2 text-sm/6 font-semibold mb-4">Retour site</a>
                        <li v-for="item in navigation" :key="item.name">
                          <a @click.prevent="selectView(item)" v-if="!item.children" :href="item.href" :class="[item.current ? 'bg-[#1F2937] text-white' : 'text-[#9CA3AF] hover:bg-[#1F2937]', 'block rounded-md py-2 pl-10 pr-2 text-sm/6 font-semibold']">
                            {{ item.name }}
                          </a>
                          <Disclosure as="div" v-else v-slot="{ open }">
                            <DisclosureButton :class="[item.current ? 'bg-[#1F2937] text-white' : 'text-[#9CA3AF] hover:bg-[#1F2937]', 'flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm/6 font-semibold']">
                              <ChevronRightIcon :class="[open ? 'rotate-90 text-gray-500' : 'text-gray-400', 'size-5 shrink-0']" aria-hidden="true"/>
                              {{ item.name }}
                            </DisclosureButton>
                            <DisclosurePanel as="ul" class="mt-1 px-2">
                              <li v-for="subItem in item.children" :key="subItem.name">
                                <DisclosureButton as="a" @click.prevent="selectView(subItem)" :href="subItem.href" :class="[subItem.current ? 'bg-[#1F2937] text-white' : 'text-[#9CA3AF] hover:bg-[#1F2937]', 'block rounded-md py-2 pl-9 pr-2 text-sm/6']">
                                  {{ subItem.name }}
                                </DisclosureButton>
                              </li>
                            </DisclosurePanel>
                          </Disclosure>
                        </li>
                      </ul>
                    </li>

                    <li class="-mx-6 mt-auto">
                      <a href="/" class="text-[#9CA3AF] hover:bg-red-500 bg-gray-700 hover:text-white justify-center block py-4 pl-10 pr-2 text-sm/6 font-semibold">
                        Déconnexion
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-600 bg-[#111827] px-6">
        <div class="flex h-16 shrink-0 items-center">
          <img class="h-10 w-auto rounded-xl shadow" src="@/assets/Logos/logo.png"/>
        </div>
        <nav class="flex flex-1 flex-col">
          <ul role="list" class="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" class="-mx-2 space-y-1">
                <a href="/" class="text-[#9CA3AF] hover:bg-[#1F2937] hover:text-white block rounded-md py-2 pl-10 pr-2 text-sm/6 font-semibold mb-6">Retour site</a>
                <li v-for="item in navigation" :key="item.name">
                  <a @click.prevent="selectView(item)" v-if="!item.children" :href="item.href" :class="[item.current ? 'bg-[#1F2937] text-white' : 'text-[#9CA3AF] hover:bg-[#1F2937]', 'block rounded-md py-2 pl-10 pr-2 text-sm/6 font-semibold']">
                    {{ item.name }}
                  </a>
                  <Disclosure as="div" v-else v-slot="{ open }">
                    <DisclosureButton :class="[item.current ? 'bg-[#1F2937] text-white' : 'text-[#9CA3AF] hover:bg-[#1F2937]', 'flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm/6 font-semibold']">
                      <ChevronRightIcon :class="[open ? 'rotate-90 text-gray-500' : 'text-gray-400', 'size-5 shrink-0']" aria-hidden="true"/>
                      {{ item.name }}
                    </DisclosureButton>
                    <DisclosurePanel as="ul" class="mt-1 px-2">
                      <li v-for="subItem in item.children" :key="subItem.name">
                        <DisclosureButton as="a" @click.prevent="selectView(subItem)" :href="subItem.href" :class="[subItem.current ? 'bg-[#1F2937] text-white' : 'text-[#9CA3AF] hover:bg-[#1F2937]', 'block rounded-md py-2 pl-9 pr-2 text-sm/6']">
                          {{ subItem.name }}
                        </DisclosureButton>
                      </li>
                    </DisclosurePanel>
                  </Disclosure>
                </li>
              </ul>
            </li>
            <li class="-mx-6 mt-auto">
              <a href="/" class="text-[#9CA3AF] hover:bg-red-500 bg-gray-700 hover:text-white justify-center block py-4 pl-10 pr-2 text-sm/6 font-semibold">
                Déconnexion
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div class="sticky top-0 z-40 flex items-center gap-x-6 bg-[#1F2937] px-4 py-4 shadow-sm sm:px-6 lg:hidden">
      <button type="button" class="-m-2.5 p-2.5 text-indigo-200 lg:hidden" @click="sidebarOpen = true">
        <span class="sr-only">Open sidebar</span>
        <Bars3Icon class="size-6" aria-hidden="true" />
      </button>
      <div class="flex-1 text-sm/6 font-semibold text-white justify-between w-full flex">TABLEAU FNMM <small class="text-gray-700">.HBC</small></div>
    </div>

    <main class="py-10 lg:pl-72">
      <div class="px-4 sm:px-6 lg:px-8">
        <component :is="activeView"></component>
      </div>
    </main>
  </div>
</template>
