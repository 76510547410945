<script setup>
import { ref, onMounted, computed } from 'vue';
import { DocumentTextIcon } from '@heroicons/vue/24/outline';

const documents = ref([]);

// Tableau des mois en français avec leurs différentes variations
const MONTHS_MAPPING = {
  'JANVIER': ['janvier', 'Janvier', 'JANVIER'],
  'FÉVRIER': ['fevrier', 'février', 'Fevrier', 'Février', 'FEVRIER', 'FÉVRIER'],
  'MARS': ['mars', 'Mars', 'MARS'],
  'AVRIL': ['avril', 'Avril', 'AVRIL'],
  'MAI': ['mai', 'Mai', 'MAI'],
  'JUIN': ['juin', 'Juin', 'JUIN'],
  'JUILLET': ['juillet', 'Juillet', 'JUILLET'],
  'AOÛT': ['aout', 'août', 'Aout', 'Août', 'AOUT', 'AOÛT'],
  'SEPTEMBRE': ['septembre', 'Septembre', 'SEPTEMBRE'],
  'OCTOBRE': ['octobre', 'Octobre', 'OCTOBRE'],
  'NOVEMBRE': ['novembre', 'Novembre', 'NOVEMBRE'],
  'DÉCEMBRE': ['decembre', 'décembre', 'Decembre', 'Décembre', 'DECEMBRE', 'DÉCEMBRE']
};

// Fonction pour normaliser le nom du mois
const normalizeMonth = (month) => {
  for (const [standardMonth, variations] of Object.entries(MONTHS_MAPPING)) {
    if (variations.includes(month)) {
      return standardMonth;
    }
  }
  return null;
};

// Fonction pour parser le nom du document et extraire la date
const parseDocumentDate = (name) => {
  // Format attendu: "mois année" (avec différentes casses possibles)
  const parts = name.trim().split(' ');
  if (parts.length === 2) {
    const normalizedMonth = normalizeMonth(parts[0]);
    const year = parseInt(parts[1]);
    if (normalizedMonth && !isNaN(year)) {
      const monthIndex = Object.keys(MONTHS_MAPPING).indexOf(normalizedMonth);
      return new Date(year, monthIndex);
    }
  }
  return null;
};

// Fonction pour formater le nom du document
const formatDocumentName = (name) => {
  const date = parseDocumentDate(name);
  if (date) {
    const month = Object.keys(MONTHS_MAPPING)[date.getMonth()];
    const year = date.getFullYear();
    return `${month} ${year}`;
  }
  return name;
};

// Documents triés par date
const sortedDocuments = computed(() => {
  return [...documents.value].sort((a, b) => {
    const dateA = parseDocumentDate(a.name);
    const dateB = parseDocumentDate(b.name);
    
    // Si les deux documents ont des dates valides
    if (dateA && dateB) {
      return dateB - dateA; // Tri décroissant
    }
    
    // Si seulement un document a une date valide
    if (dateA) return -1;
    if (dateB) return 1;
    
    // Si aucun document n'a de date valide, tri alphabétique
    return a.name.localeCompare(b.name);
  }).map(doc => ({
    ...doc,
    displayName: formatDocumentName(doc.name)
  }));
});

const fetchDocuments = async () => {
  try {
    const response = await fetch('https://api.meritemaritime-fnmm.com/api/documents-du-mois/');
    if (response.ok) {
      documents.value = await response.json();
    }
  } catch (error) {
    console.error('Erreur lors du chargement des documents:', error);
  }
};

onMounted(() => {
  fetchDocuments();
});
</script>

<template>
  <div class="py-28 w-full bg-[#072653] min-h-[450px] mt-[136px] lg:mt-[160px]">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <h2 class="text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">CENTRE DE DOCUMENTATION MARITIME</h2>
        <p class="mt-6 text-lg/8 text-gray-200">Explorez notre base de ressources dédiée au patrimoine maritime de la Fédération Nationale du Mérite Maritime et de la Médaille d'Honneur des Marins.</p>
        <div class="grid grid-cols-1 mt-6 sm:grid-cols-2 lg:grid-cols-2 gap-x-3 gap-y-4 mx-auto w-full">
          <a href="https://president266.wixsite.com/fnmmcdm" target="_blank" class="text-gray-200 hover:text-violet-600 shadow flex py-2 rounded justify-center items-center border">
            Accéder au site du CDM
          </a>
          <a href="https://president266.wixsite.com/fnmmcdm/contacts" target="_blank" class="text-gray-200 hover:text-violet-600 shadow flex py-2 rounded justify-center items-center border">
            Contacter le CDM
          </a>
        </div>
      </div>
    </div>
  </div>
  
  <div class="bg-neutral-50 py-12">
    <div class="mx-auto max-w-7xl px-6">
      <div class="flex items-center gap-3 mb-8">
        <DocumentTextIcon class="h-8 w-8 text-indigo-600" />
        <h3 class="text-2xl font-semibold text-gray-900">Documents du mois</h3>
      </div>
      
      <!-- Grille de documents -->
      <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        <div v-for="doc in sortedDocuments" :key="doc.id" 
             class="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white hover:border-indigo-600 transition-colors duration-200">
          <div class="flex flex-1 flex-col p-6">
            <div class="flex items-center gap-3">
              <DocumentTextIcon class="h-8 w-8 text-indigo-600 flex-shrink-0" />
              <h3 class="text-lg font-medium text-gray-900">{{ doc.displayName }}</h3>
            </div>
            
            <div class="mt-6 flex flex-1 items-end">
              <a
                :href="doc.url_pdf"
                target="_blank"
                class="w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-colors duration-200"
              >
                Consulter le document
              </a>
            </div>
          </div>
          
          <!-- Effet de survol -->
          <div class="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-indigo-600 via-blue-600 to-purple-600 transform origin-left transition-transform duration-200 ease-out scale-x-0 group-hover:scale-x-100"></div>
        </div>
      </div>

      <!-- Message si aucun document -->
      <div v-if="documents.length === 0" class="text-center py-12">
        <DocumentTextIcon class="mx-auto h-12 w-12 text-gray-400" />
        <h3 class="mt-2 text-sm font-semibold text-gray-900">Aucun document</h3>
        <p class="mt-1 text-sm text-gray-500">Les documents du mois seront bientôt disponibles.</p>
      </div>
    </div>
  </div>
</template>