<template>
  <div class="pb-24 sm:pb-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl text-center">
        <h2 class="text-balance text-4xl font-semibold tracking-tight text-gray-200 sm:text-5xl">Articles Fédération</h2>
        <div class="col-span-full">
          <div class="mt-4">
            <input
              v-model="searchQuery"
              type="text"
              name="search"
              id="search"
              placeholder="Chercher un article"
              class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6"
            />
          </div>
        </div>
      </div>
      <div class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-2">
        <!-- Bouton Ajouter un article -->
        <button
          type="button"
          class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          @click="handleAddArticleClick"
        >
          <svg
            class="mx-auto size-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 4h24l8 8v32H6V4z"
            />
            <line
              x1="12"
              y1="20"
              x2="32"
              y2="20"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></line>
            <line
              x1="12"
              y1="26"
              x2="28"
              y2="26"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></line>
            <line
              x1="12"
              y1="32"
              x2="24"
              y2="32"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></line>
          </svg>
          <span class="mt-2 block text-sm font-semibold text-white">Ajouter un article</span>
        </button>

        <!-- Liste des articles -->
        <article
          v-for="post in filteredPosts"
          :key="post.id"
          class="flex flex-col items-start justify-between bg-gray-900 p-6 border-gray-700 border rounded-xl hover:bg-gray-700 shadow cursor-pointer relative"
        >
          <div @click="setFederationArticle(post)" class="w-full">
            <div class="relative w-full">
              <img
                :src="post.url_image"
                alt=""
                class="aspect-video w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
              />
              <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>
            </div>
            <div class="max-w-xl w-full">
              <div class="mt-8 flex items-center gap-x-4 text-xs">
                <time :datetime="post.datetime" class="text-gray-300">{{ post.date }}</time>
                <span v-if="post.features === 1" class="inline-flex items-center rounded-md bg-green-400/10 px-2 py-1 text-xs font-medium text-green-400 ring-1 ring-inset ring-green-400/20">
                  Article épinglé
                </span>
              </div>
              <div class="group relative">
                <h3 class="mt-3 text-lg/6 font-semibold text-gray-100">
                  <a :href="post.url_pdf ? post.url_pdf : '#'">
                    <span class="absolute inset-0"></span>
                    {{ post.titre }}
                  </a>
                </h3>
                <p class="mt-5 line-clamp-3 text-sm/6 text-gray-200">{{ post.description }}</p>
              </div>
            </div>
          </div>
          
          <div class="mt-4 flex gap-2">
            
            <button
              @click="deleteArticle($event, post.id)"
              class="px-4 py-2 bg-red-600 hover:bg-red-700 rounded-md text-white flex items-center gap-2"
              title="Supprimer l'article"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="size-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
              Supprimer
            </button>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';

// Déclarer les événements émis
const emit = defineEmits(['view-article']);

// Définir l'état pour les articles
const posts = ref([]);
const federationArticle = ref(0); // 0 par défaut, signifie qu'aucun article n'est sélectionné

// Ajouter une ref pour la recherche
const searchQuery = ref('');

// Ajouter un computed pour filtrer les articles
const filteredPosts = computed(() => {
  return posts.value.filter(post => 
    post.titre.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});

// Fonction pour récupérer les articles depuis l'API
const fetchArticles = async () => {
  try {
    const response = await fetch('https://api.meritemaritime-fnmm.com/federation/article');
    const data = await response.json();
    posts.value = data;
  } catch (error) {
    console.error('Erreur lors de la récupération des articles:', error);
  }
};

// Mettre à jour federationArticle avec l'ID de l'article ou 0 si on clique sur "Ajouter un article"
const setFederationArticle = (article) => {
  if (article === 0) {
    federationArticle.value = 0;
    localStorage.removeItem('FederationArticleDetails');
  } else {
    federationArticle.value = 1;
    localStorage.setItem('FederationArticleDetails', JSON.stringify(article));
    emit('view-article', { action: 'add', articleId: null }); // Émettre l'événement vers le parent

  }
  localStorage.setItem('federationArticle', federationArticle.value);
};

// Fonction pour gérer le clic sur "Ajouter un article"
const handleAddArticleClick = () => {
  setFederationArticle(0); // Appeler la logique existante
  emit('view-article', { action: 'add', articleId: null }); // Émettre l'événement vers le parent
};

// Fonction pour épingler/désépingler un article
const togglePin = async (event, post) => {
  event.stopPropagation();
  try {
    const response = await fetch(`https://api.meritemaritime-fnmm.com/sections/update/features/${post.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        features: post.features === 1 ? 0 : 1
      })
    });
    
    if (response.ok) {
      // Rafraîchir la liste des articles après la mise à jour
      await fetchArticles();
    } else {
      console.error('Erreur lors de la mise à jour du statut d\'épinglage');
    }
  } catch (error) {
    console.error('Erreur lors de la mise à jour:', error);
  }
};

// Ajouter la fonction de suppression
const deleteArticle = async (event, articleId) => {
  event.stopPropagation(); // Empêche le déclenchement du clic sur l'article parent
  
  if (confirm('Êtes-vous sûr de vouloir supprimer cet article ?')) {
    try {
      const response = await fetch(`https://api.meritemaritime-fnmm.com/federation/delete/article/${articleId}`, {
        method: 'DELETE',
      });
      
      if (response.ok) {
        // Rafraîchir la liste des articles après la suppression
        await fetchArticles();
      } else {
        console.error('Erreur lors de la suppression');
      }
    } catch (error) {
      console.error('Erreur lors de la suppression:', error);
    }
  }
};

// Appeler fetchArticles lors du montage du composant
onMounted(() => {
  fetchArticles();
});
</script>
