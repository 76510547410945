<template>
    <div>
        <form @submit.prevent="handleSubmit">
          <div class="space-y-12">
            <div class="border-b border-gray-900/10 pb-12">
              <h2 class="text-2xl font-semibold text-gray-200">Documents du mois</h2>
              <p class="mt-1 text-sm/6 text-gray-300">Ajoutez un document du mois pour le mois en cours</p>
              <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 border-gray-700 border p-6 bg-gray-900 rounded-xl shadow">
                <!-- Champ titre -->
                <div class="col-span-full">
                  <label for="title" class="block text-sm/6 font-medium text-white">Document du mois</label>
                  <div class="mt-2">
                    <input 
                      v-model="title" 
                      type="text" 
                      name="title" 
                      id="title" 
                      placeholder="Nom du document" 
                      class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" 
                    />
                  </div>
                </div>
    
                <!-- Sélectionner un fichier -->
                <div class="col-span-full">
                  <label for="file-upload" class="block text-sm/6 font-medium text-white">Sélectionner un fichier PDF</label>
                  <label for="file-upload" class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-200/25 px-6 py-10 cursor-pointer">
                    <div class="text-center">
                      <DocumentIcon class="mx-auto size-12 text-gray-300" aria-hidden="true" />
                      <div class="mt-4 flex text-sm/6 text-gray-600">
                        <input 
                          id="file-upload" 
                          name="file-upload" 
                          type="file" 
                          class="sr-only" 
                          accept="application/pdf" 
                          @change="handleFileChange" 
                        />
                        <p class="pl-1">ou glisser-déposer ici</p>
                      </div>
                      <p class="text-xs/5 text-gray-600">PDF uniquement, jusqu'à 10MB</p>
                    </div>
                  </label>
                </div>
    
                <!-- Aperçu du fichier sélectionné -->
                <div v-if="file" class="col-span-full">
                  <div class="flex items-center space-x-2 text-sm text-gray-300">
                    <DocumentIcon class="h-5 w-5" />
                    <span>{{ file.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
    
          <!-- Bouton de soumission -->
          <div class="mt-6 flex items-center justify-end gap-x-6">
            <button type="submit" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
              Envoyer
            </button>
          </div>
        </form>

        <!-- Liste des documents -->
        <div class="mt-8">
            <h3 class="text-xl font-semibold text-gray-200 mb-4">Liste des documents du mois</h3>
            <div class="space-y-4">
                <div v-for="doc in documents" :key="doc.id" class="flex items-center justify-between p-4 bg-gray-900 rounded-lg border border-gray-700">
                    <div class="flex items-center space-x-4">
                        <DocumentIcon class="h-8 w-8 text-gray-300" />
                        <span class="text-gray-200">{{ doc.name }}</span>
                    </div>
                    <div class="flex space-x-2">
                        <a :href="doc.url_pdf" target="_blank" class="px-3 py-1 text-sm bg-blue-600 text-white rounded hover:bg-blue-500">
                            Voir le PDF
                        </a>
                        <button @click="deleteDocument(doc.id)" class="px-3 py-1 text-sm bg-red-600 text-white rounded hover:bg-red-500">
                            Supprimer
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { DocumentIcon } from '@heroicons/vue/24/outline'

const title = ref('')
const file = ref(null)
const documents = ref([])

// Fonction pour charger les documents
const fetchDocuments = async () => {
    try {
        const response = await fetch('https://api.meritemaritime-fnmm.com/api/documents-du-mois/')
        if (response.ok) {
            documents.value = await response.json()
        }
    } catch (error) {
        console.error('Erreur lors du chargement des documents:', error)
    }
}

// Fonction pour supprimer un document
const deleteDocument = async (id) => {
    if (!confirm('Êtes-vous sûr de vouloir supprimer ce document ?')) return

    try {
        const response = await fetch(`https://api.meritemaritime-fnmm.com/api/documents-du-mois/${id}`, {
            method: 'DELETE'
        })
        if (response.ok) {
            await fetchDocuments() // Recharger la liste
            alert('Document supprimé avec succès !')
        }
    } catch (error) {
        console.error('Erreur lors de la suppression:', error)
        alert('Erreur lors de la suppression du document.')
    }
}

// Fonction pour gérer le changement de fichier
const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]
    file.value = selectedFile
}

// Fonction pour soumettre les données
const handleSubmit = async () => {
    if (!file.value) {
        alert('Veuillez sélectionner un fichier !')
        return
    }

    if (!title.value) {
        alert('Veuillez entrer un nom pour le document !')
        return
    }

    const formData = new FormData()
    formData.append('name', title.value)
    formData.append('pdf', file.value)

    try {
        const response = await fetch('https://api.meritemaritime-fnmm.com/api/documents-du-mois/', {
            method: 'POST',
            body: formData,
        })

        if (response.ok) {
            alert('Document ajouté avec succès !')
            await fetchDocuments() // Recharger la liste
            // Réinitialisation des champs
            title.value = ''
            file.value = null
        } else {
            const errorData = await response.json()
            alert('Erreur: ' + errorData.message)
        }
    } catch (error) {
        console.error('Erreur lors de l\'envoi du document:', error)
        alert('Une erreur est survenue.')
    }
}

// Charger les documents au montage du composant
onMounted(() => {
    fetchDocuments()
})
</script>
  