<template>
  <div class="mt-12 bg-[#111827] p-6 rounded-xl shadow border-gray-700 border">
    <!-- Statistiques de visites -->
    <div>
      <h4 class="text-lg font-medium text-white mb-4">Statistiques de visites</h4>
      
      <!-- Statistiques globales -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
        <!-- Visites en 24h -->
        <div class="bg-[#1F2937] p-6 rounded-lg border border-gray-700">
          <div class="flex items-center justify-between mb-2">
            <h5 class="text-sm font-medium text-gray-400">Visites en 24h</h5>
            <div class="w-8 h-8 rounded-full bg-blue-500/10 flex items-center justify-center">
              <svg class="w-4 h-4 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
          </div>
          <div class="text-3xl font-bold text-white">{{ visits24h }}</div>
          <div class="mt-2 flex items-center text-sm" :class="visits24hTrend >= 0 ? 'text-green-500' : 'text-red-500'">
            <svg class="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path v-if="visits24hTrend >= 0" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
              <path v-else stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 17h8m0 0v-8m0 8l-8-8-4 4-6-6" />
            </svg>
            {{ Math.abs(visits24hTrend).toFixed(2) }}% par rapport à hier
          </div>
        </div>

        <!-- Visites totales -->
        <div class="bg-[#1F2937] p-6 rounded-lg border border-gray-700">
          <div class="flex items-center justify-between mb-2">
            <h5 class="text-sm font-medium text-gray-400">Visites totales</h5>
            <div class="w-8 h-8 rounded-full bg-green-500/10 flex items-center justify-center">
              <svg class="w-4 h-4 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
              </svg>
            </div>
          </div>
          <div class="text-3xl font-bold text-white">{{ totalVisits }}</div>
          <div class="mt-2 text-sm text-gray-400">
            Depuis le début
          </div>
        </div>
      </div>

      <!-- Graphique des visites quotidiennes -->
      <div class="mb-8 bg-[#1F2937] p-4 rounded-lg border border-gray-700">
        <h5 class="text-sm font-medium text-gray-400 mb-4">Visites quotidiennes</h5>
        <div class="h-64">
          <Line :data="chartData" :options="chartOptions" />
        </div>
      </div>

      <!-- Routes les plus visitées -->
      <div class="mb-8">
        <h5 class="text-sm font-medium text-gray-400 mb-4">Routes les plus visitées</h5>
        <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-700">
            <thead class="bg-[#1F2937]">
              <tr>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6">Route</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-white">Visites</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-white">% du total</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-700 bg-[#1F2937]">
              <tr v-for="route in topRoutes" :key="route.path">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-200 sm:pl-6">{{ route.path }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-200">{{ route.visits }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-200">
                  <div class="w-full bg-gray-700 rounded-full h-2">
                    <div class="bg-blue-500 h-2 rounded-full" :style="{ width: `${(route.visits / Math.max(...topRoutes.map(r => r.visits))) * 100}%` }"></div>
                  </div>
                  <span class="text-xs text-gray-400">{{ Math.round((route.visits / Math.max(...topRoutes.map(r => r.visits))) * 100) }}%</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Villes les plus visitées -->
      <div>
        <h5 class="text-sm font-medium text-gray-400 mb-4">Villes les plus visitées</h5>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div v-for="(city, index) in topCities" :key="index" 
               class="bg-[#1F2937] p-4 rounded-lg border border-gray-700">
            <div class="flex items-center justify-between mb-2">
              <span class="text-sm font-medium text-white">{{ city.name }}</span>
              <span class="text-sm text-gray-400">{{ city.country }}</span>
            </div>
            <div class="text-2xl font-bold text-white mb-2">{{ city.visits }}</div>
            <div class="w-full bg-gray-700 rounded-full h-2">
              <div class="bg-green-500 h-2 rounded-full" :style="{ width: `${(city.visits / Math.max(...topCities.map(c => c.visits))) * 100}%` }"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed, watch } from 'vue'
import axios from 'axios'
import { Line } from 'vue-chartjs'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
)

const REFRESH_INTERVAL = 9000 // 9 secondes en millisecondes
let statsInterval

// Données des visites quotidiennes
const dailyVisits = ref([])

// Données des routes les plus visitées
const topRoutes = ref([])

// Données des villes les plus visitées
const topCities = ref([])

// Statistiques globales
const visits24h = ref(0)
const visits24hTrend = ref(0)
const totalVisits = ref(0)

// Configuration du graphique
const chartData = computed(() => {
  if (!dailyVisits.value || dailyVisits.value.length === 0) {
    return {
      labels: [],
      datasets: [{
        label: 'Visites',
        data: [],
        borderColor: '#3B82F6',
        backgroundColor: 'rgba(59, 130, 246, 0.2)',
        fill: true,
        tension: 0.4,
        pointBackgroundColor: '#3B82F6',
        pointBorderColor: '#fff',
        pointBorderWidth: 2,
        pointRadius: 4,
        pointHoverRadius: 6
      }]
    }
  }

  return {
    labels: dailyVisits.value.map(visit => formatDate(visit.date)),
    datasets: [{
      label: 'Visites',
      data: dailyVisits.value.map(visit => visit.count),
      borderColor: '#3B82F6',
      backgroundColor: 'rgba(59, 130, 246, 0.2)',
      fill: true,
      tension: 0.4,
      pointBackgroundColor: '#3B82F6',
      pointBorderColor: '#fff',
      pointBorderWidth: 2,
      pointRadius: 4,
      pointHoverRadius: 6
    }]
  }
})

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    }
  },
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        color: 'rgba(255, 255, 255, 0.1)'
      },
      ticks: {
        color: '#9CA3AF'
      }
    },
    x: {
      grid: {
        display: false
      },
      ticks: {
        color: '#9CA3AF'
      }
    }
  }
}

// Fonction pour formater la date
const formatDate = (dateString) => {
  if (!dateString) return ''
  const date = new Date(dateString)
  return date.toLocaleDateString('fr-FR', {
    day: 'numeric',
    month: 'short'
  })
}

// Fonction pour récupérer les statistiques
const fetchStats = async () => {
  try {
    const response = await axios.get('https://api.meritemaritime-fnmm.com/stats/visits/stats')
    console.log('Réponse API:', response.data)
    
    if (response.data) {
      // S'assurer que les données sont dans le bon format
      dailyVisits.value = Array.isArray(response.data.daily) ? response.data.daily : []
      topRoutes.value = Array.isArray(response.data.routes) ? response.data.routes : []
      topCities.value = Array.isArray(response.data.cities) ? response.data.cities : []
      
      // Trier les données par date pour dailyVisits
      dailyVisits.value.sort((a, b) => new Date(a.date) - new Date(b.date))
      
      // Trier les routes et villes par nombre de visites
      topRoutes.value.sort((a, b) => b.visits - a.visits)
      topCities.value.sort((a, b) => b.visits - a.visits)
      
      console.log('Données formatées:', {
        dailyVisits: dailyVisits.value,
        topRoutes: topRoutes.value,
        topCities: topCities.value
      })
      
      // Calcul des statistiques globales
      const today = new Date()
      const yesterday = new Date(today)
      yesterday.setDate(yesterday.getDate() - 1)
      
      // Visites en 24h
      const todayVisits = dailyVisits.value.find(v => new Date(v.date).toDateString() === today.toDateString())?.count || 0
      const yesterdayVisits = dailyVisits.value.find(v => new Date(v.date).toDateString() === yesterday.toDateString())?.count || 0
      
      visits24h.value = todayVisits
      visits24hTrend.value = yesterdayVisits ? ((todayVisits - yesterdayVisits) / yesterdayVisits) * 100 : 0
      
      // Visites totales
      totalVisits.value = dailyVisits.value.reduce((sum, v) => sum + v.count, 0)
    }
  } catch (error) {
    console.error('Erreur lors de la récupération des statistiques:', error)
  }
}

// Configuration de l'intervalle au montage du composant
onMounted(() => {
  fetchStats() // Récupération initiale des statistiques
  
  // Mise à jour des informations toutes les 9 secondes
  statsInterval = setInterval(() => {
    fetchStats()
  }, REFRESH_INTERVAL)
})

// Nettoyage des intervalles au démontage du composant
onUnmounted(() => {
  if (statsInterval) clearInterval(statsInterval)
})

// Ajouter un watcher pour surveiller les changements de données
watch(dailyVisits, (newValue) => {
  console.log('dailyVisits changé:', newValue)
}, { immediate: true })

watch(topRoutes, (newValue) => {
  console.log('topRoutes changé:', newValue)
}, { immediate: true })

watch(topCities, (newValue) => {
  console.log('topCities changé:', newValue)
}, { immediate: true })
</script>
