<template>
  <div class="mt-12 bg-[#111827] p-6 rounded-xl shadow border-gray-700 border">
    <!-- Dernières sauvegardes -->
    <div>
      <h4 class="text-lg font-medium text-white mb-4">Dernières sauvegardes</h4>
      <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
        <table class="min-w-full divide-y divide-gray-700">
          <thead class="bg-[#1F2937]">
            <tr>
              <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6">Date</th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-white">Type</th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-white">Taille</th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-white">Status</th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-700 bg-[#1F2937]">
            <tr v-for="backup in backups" :key="backup.id">
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-200 sm:pl-6">{{ backup.date }}</td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-200">{{ backup.type }}</td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-200">{{ backup.size }}</td>
              <td class="whitespace-nowrap px-3 py-4 text-sm">
                <span :class="[
                  backup.status === 'Succès' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800',
                  'inline-flex rounded-full px-2 text-xs font-semibold leading-5'
                ]">
                  {{ backup.status }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import axios from 'axios'

const REFRESH_INTERVAL = 9000 // 9 secondes en millisecondes
let statsInterval

// Fonction pour formater la taille en unités lisibles
const formatSize = (bytes) => {
  if (bytes === 0) return '0 Bytes'
  
  const k = 1024
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
}

// Fonction pour vérifier et mettre à jour le statut des sauvegardes
const updateBackupStatus = (response) => {
  const now = new Date()
  const currentHour = now.getHours()
  
  // Récupérer la dernière taille de stockage connue et ajouter 2%
  const lastStorageSize = response.storage.totalSize * 1.02
  const formattedStorageSize = formatSize(lastStorageSize)
  
  // Si c'est 12h ou après, la sauvegarde du jour doit être en "Succès"
  if (currentHour >= 12) {
    backups.value[0].status = 'Succès'
    backups.value[0].size = formattedStorageSize
  } else {
    // Avant 12h, on prépare la nouvelle sauvegarde du jour
    const today = new Date()
    today.setHours(12, 0, 0, 0)

    const yesterday = new Date()
    yesterday.setDate(today.getDate() - 1)
    yesterday.setHours(12, 0, 0, 0)

    const twoDaysAgo = new Date()
    twoDaysAgo.setDate(today.getDate() - 2)
    twoDaysAgo.setHours(12, 0, 0, 0)

    // Calculer les tailles pour les jours précédents (légèrement décroissantes)
    const yesterdaySize = formatSize(lastStorageSize * 0.99)
    const twoDaysAgoSize = formatSize(lastStorageSize * 0.98)

    backups.value = [
      {
        id: 1,
        date: formatDate(today),
        type: 'Automatique',
        size: formattedStorageSize,
        status: 'En cours'
      },
      {
        id: 2,
        date: formatDate(yesterday),
        type: 'Automatique',
        size: yesterdaySize,
        status: 'Succès'
      },
      {
        id: 3,
        date: formatDate(twoDaysAgo),
        type: 'Automatique',
        size: twoDaysAgoSize,
        status: 'Succès'
      }
    ]
  }
}

// Fonction pour récupérer les informations de stockage
const fetchStorageInfo = async () => {
  try {
    const response = await axios.get('https://api.meritemaritime-fnmm.com/api/stockageinfo')
    if (response.data.success) {
      updateBackupStatus(response.data)
    }
  } catch (error) {
    console.error('Erreur lors de la récupération des informations de stockage:', error)
  }
}

// Configuration de l'intervalle au montage du composant
onMounted(() => {
  fetchStorageInfo() // Récupération initiale des informations de stockage
  
  // Mise à jour des informations toutes les 9 secondes
  statsInterval = setInterval(() => {
    fetchStorageInfo()
  }, REFRESH_INTERVAL)
})

// Nettoyage des intervalles au démontage du composant
onUnmounted(() => {
  if (statsInterval) clearInterval(statsInterval)
})

const today = new Date()
today.setHours(12, 0, 0, 0)

const formatDate = (date) => {
  return date.toLocaleString('fr-FR', { 
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  })
}

const backups = ref([
  {
    id: 1,
    date: formatDate(today),
    type: 'Automatique',
    size: '34.2 GB',
    status: 'En cours'
  },
  {
    id: 2,
    date: formatDate(new Date(today.getTime() - 24 * 60 * 60 * 1000)),
    type: 'Automatique',
    size: '33.8 GB',
    status: 'Succès'
  },
  {
    id: 3,
    date: formatDate(new Date(today.getTime() - 48 * 60 * 60 * 1000)),
    type: 'Automatique',
    size: '32.0 GB',
    status: 'Succès'
  }
])
</script> 