<template>
  <form @submit.prevent="handleSubmit">
    <div class="space-y-12">
      <div class="pb-12">

        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div class="col-span-full">
            <label for="title" class="block text-sm/6 font-medium text-white">Titre</label>
            <div class="mt-2">
              <input v-model="form.title" type="text" name="title" id="title" placeholder="-" class="block w-full rounded-md border-0 bg-white/5 py-4 px-4 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
            </div>
          </div>

          <div class="col-span-full">
            <label for="url" class="block text-sm/6 font-medium text-white">URL image</label>
            <div class="mt-2">
              <input v-model="form.url" type="text" name="url" id="url" placeholder="-" class="block py-4 px-4 w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
            </div>
          </div>

          <div class="col-span-full">
            <label for="about" class="block text-sm/6 font-medium text-white">Description</label>
            <div class="mt-2">
              <textarea v-model="form.about" id="about" name="about" rows="3" placeholder="-" class="block w-full rounded-md border-0 bg-white/5 py-4 px-4 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
            </div>
          </div>
          <div class="col-span-full">
            <label for="file-upload" class="block text-sm font-medium text-gray-300">Fichier PDF</label>
            <label
              for="file-upload"
              class="mt-2 flex cursor-pointer justify-center rounded-lg border border-dashed border-gray-500 px-6 py-10"
            >
              <div class="text-center">
                <PaperClipIcon class="mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />
                <div class="mt-4 flex text-sm text-gray-300">
                  <label
                    for="file-upload"
                    class="relative cursor-pointer rounded-md font-semibold text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-green-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900 hover:text-green-500"
                  >
                    <span>Choisir un fichier</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      accept=".pdf"
                      @change="handleFileUpload"
                      class="sr-only"
                    />
                  </label>
                  <p class="pl-1">ou glisser-déposer</p>
                </div>
                <p class="text-xs text-gray-400">PDF jusqu'à 10MB</p>
              </div>
            </label>
          </div>
          
          <!-- Affichage du PDF sélectionné -->
          <div v-if="selectedFile" class="col-span-full mt-4">
            <div class="flex items-center justify-between py-4 pl-4 pr-5 text-sm/6 bg-white/5 rounded-lg">
              <div class="flex items-center">
                <PaperClipIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                <span class="ml-2 text-white">{{ selectedFile.name }}</span>
              </div>
              <button 
                type="button" 
                @click="removeSelectedFile" 
                class="text-red-400 hover:text-red-300"
              >
                <TrashIcon class="h-5 w-5" />
              </button>
            </div>
          </div>

          <!-- Affichage du PDF après création -->
          <div v-if="createdPdfUrl" class="col-span-full mt-4">
            <div class="flex items-center justify-between py-4 pl-4 pr-5 text-sm/6 bg-white/5 rounded-lg">
              <div class="flex items-center">
                <PaperClipIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                <span class="ml-2 text-white">Document PDF</span>
              </div>
              <div class="flex items-center gap-4">
                <a 
                  :href="createdPdfUrl" 
                  target="_blank" 
                  class="text-green-400 hover:text-green-300"
                >
                  Voir le PDF
                </a>
                <a 
                  :href="createdPdfUrl" 
                  download 
                  class="text-blue-400 hover:text-blue-300"
                >
                  Télécharger
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6 flex items-center justify-end gap-x-6">
      <!-- Bouton Annuler qui émet un événement 'close' pour fermer le composant -->
      <button type="button" @click="closeForm" class="text-sm/6 font-semibold text-white">Annuler</button>
      <button type="submit" class="rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500">Crée</button>
    </div>
  </form>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';
import { defineEmits } from 'vue';
import { PaperClipIcon, TrashIcon } from '@heroicons/vue/20/solid';

const emit = defineEmits();

const form = ref({
  title: '',
  url: '',
  about: ''
});

const selectedFile = ref(null);
const createdPdfUrl = ref(null);

const handleFileUpload = (event) => {
  selectedFile.value = event.target.files[0];
};

const removeSelectedFile = () => {
  selectedFile.value = null;
};

const handleSubmit = async () => {
  try {
    const selectedSectionId = localStorage.getItem('selectedSectionId');

    const formData = new FormData();
    formData.append('title', form.value.title);
    formData.append('about', form.value.about);
    formData.append('url', form.value.url);
    formData.append('selectedSectionId', selectedSectionId);
    
    if (selectedFile.value) {
      formData.append('pdf', selectedFile.value);
    }

    const response = await axios.post('https://api.meritemaritime-fnmm.com/sections/article/create', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    console.log('Réponse API:', response.data);
    location.reload();
    // Stocker l'URL du PDF créé
    if (response.data.pdf_url) {
      createdPdfUrl.value = response.data.pdf_url;
    }

    // Ne pas fermer le formulaire immédiatement si un PDF a été créé
    if (!response.data.pdf_url) {
      emit('close');
    }
  } catch (error) {
    console.error('Erreur lors de l\'envoi du formulaire:', error);
  }
};

const closeForm = () => {
  emit('close');
};
</script>
