<template>
    <form @submit.prevent="handleSubmit">
      <div class="space-y-12">
        <div class="pb-12">
          <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div class="col-span-full">
              <label for="title" class="block text-sm/6 font-medium text-white">Titre</label>
              <div class="mt-2">
                <input v-model="form.title" type="text" name="title" id="title" placeholder="-" class="block w-full rounded-md border-0 bg-white/5 py-4 px-4 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
              </div>
            </div>
  
            <div class="col-span-full">
              <label for="url" class="block text-sm/6 font-medium text-white">URL image</label>
              <div class="mt-2">
                <input v-model="form.url" type="text" name="url" id="url" placeholder="-" class="block py-4 px-4 w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
              </div>
            </div>
  
            <div class="col-span-full">
              <label for="about" class="block text-sm/6 font-medium text-white">Description</label>
              <div class="mt-2">
                <textarea v-model="form.about" id="about" name="about" rows="3" placeholder="-" class="block w-full rounded-md border-0 bg-white/5 py-4 px-4 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
              </div>
            </div>

            <div class="col-span-full">
              <label for="file-upload" class="block text-sm font-medium text-gray-300">
                Fichier PDF 
                <span v-if="form.pdf_url" class="text-green-500 ml-2">(PDF actuel disponible)</span>
              </label>
              <label
                for="file-upload"
                class="mt-2 flex cursor-pointer justify-center rounded-lg border border-dashed border-gray-500 px-6 py-10"
                :class="{ 'border-green-500': form.pdf_url }"
              >
                <div class="text-center">
                  <PaperClipIcon 
                    class="mx-auto h-12 w-12" 
                    :class="form.pdf_url ? 'text-green-500' : 'text-gray-400'"
                    aria-hidden="true" 
                  />
                  <div class="mt-4 flex text-sm text-gray-300">
                    <label
                      for="file-upload"
                      class="relative cursor-pointer rounded-md font-semibold text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-green-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900 hover:text-green-500"
                    >
                      <span>{{ form.pdf_url ? 'Changer le fichier' : 'Choisir un fichier' }}</span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        accept=".pdf"
                        @change="handleFileUpload"
                        class="sr-only"
                      />
                    </label>
                    <p class="pl-1">ou glisser-déposer</p>
                  </div>
                  <p v-if="form.pdf_url" class="text-sm text-green-500 mt-2">
                    PDF actuel : {{ getPdfFileName(form.pdf_url) }}
                  </p>
                  <p class="text-xs text-gray-400">PDF jusqu'à 10MB</p>
                </div>
              </label>
            </div>

            <div v-if="form.pdf_url" class="mt-6 p-5 bg-white/5 rounded-lg shadow-md border border-white/10 transition-all duration-200 hover:bg-white/10 w-full pr-64">
              <div class="flex items-center justify-between">
                <div class="flex items-center flex-grow">
                  <PaperClipIcon class="h-6 w-6 text-green-400" />
                  <span class="text-sm font-medium text-green-400 ml-3">{{ getPdfFileName(form.pdf_url) }}</span>
                  <button
                    @click="removePdf"
                    class="group rounded-full p-1.5 hover:bg-red-500/10 transition-colors duration-200 ml-auto"
                  >
                    <TrashIcon 
                      class="h-5 w-5 text-red-400 group-hover:text-red-500 transition-colors duration-200" 
                    />
                  </button>
                </div>
              </div>
            </div>

            <!-- Nouvelle zone d'affichage du PDF actuel -->
            
        
          </div>
        </div>
      </div>
  
      <div class="mt-6 flex items-center justify-end gap-x-6">
        <button type="button" @click="closeForm" class="text-sm/6 font-semibold text-white">Annuler</button>
        <button type="submit" class="rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500">Sauvegarder</button>
      </div>
    </form>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import axios from 'axios';
  import { defineEmits } from 'vue';
  import { PaperClipIcon, TrashIcon } from '@heroicons/vue/20/solid';
  
  const emit = defineEmits();
  
  // Crée un objet "form" pour gérer les données du formulaire
  const form = ref({
    title: '',
    url: '',
    about: '',
    pdf_url: ''
  });
  
  const selectedFile = ref(null);
  
  const handleFileUpload = (event) => {
    selectedFile.value = event.target.files[0];
  };
  
  const removePdf = async () => {
    try {
      const storedPost = localStorage.getItem('selectedPost');
      const post = JSON.parse(storedPost);
      const postId = post.id;

      const formData = new FormData();
      formData.append('title', form.value.title);
      formData.append('about', form.value.about);
      formData.append('url', form.value.url);
      formData.append('postId', postId);
      formData.append('pdf_url', null);

      await axios.put('https://api.meritemaritime-fnmm.com/sections/article/update', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      form.value.pdf_url = '';
      selectedFile.value = null;
    } catch (error) {
      console.error('Erreur lors de la suppression du PDF:', error);
    }
  };
  
  // Fonction pour envoyer les données du formulaire via un appel API POST
  const handleSubmit = async () => {
    try {
      // Récupérer le post stocké dans le localStorage
      const storedPost = localStorage.getItem('selectedPost');
      const post = JSON.parse(storedPost);
      const postId = post.id; // Récupération de l'ID du post
  
      const formData = new FormData();
      formData.append('title', form.value.title);
      formData.append('about', form.value.about);
      formData.append('url', form.value.url);
      formData.append('postId', postId); // Utilisation de l'ID du post
      
      if (selectedFile.value) {
        formData.append('pdf', selectedFile.value);
      }
      if (form.value.pdf_url) {
        formData.append('old_pdf_url', form.value.pdf_url);
      }
  
      // Envoi de la requête avec axios
      const response = await axios.put('https://api.meritemaritime-fnmm.com/sections/article/update', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      console.log('Réponse API:', response.data);
      // Affiche un message de succès ou redirige l'utilisateur
    } catch (error) {
      console.error('Erreur lors de l\'envoi du formulaire:', error);
    }
  };
  
  // Fonction pour fermer le formulaire
  const closeForm = () => {
    emit('close');
  };
  
  // Récupérer l'article sélectionné depuis le localStorage et remplir le formulaire
  onMounted(() => {
    const storedPost = localStorage.getItem('selectedPost');
    if (storedPost) {
      const post = JSON.parse(storedPost); // Parse l'article JSON stocké dans localStorage
      form.value.title = post.title || '';
      form.value.url = post.img || ''; // Utilise l'URL de l'image stockée
      form.value.about = post.description || ''; // Utilise la description du post
      form.value.pdf_url = post.pdf_url || '';
    }
  });

  // Fonction pour extraire le nom du fichier de l'URL
  const getPdfFileName = (url) => {
    if (!url) return '';
    return url.split('/').pop();
  };
  </script>