import axios from 'axios';

export const recordVisit = async (route) => {
  try {
    // Récupérer la géolocalisation
    const ipResponse = await axios.get('https://ipapi.co/json/');
    const { city, region, country_name } = ipResponse.data;
    
    // Créer la date au format YYYY-MM-DD
    const today = new Date().toISOString().split('T')[0];

    await axios.post('https://api.meritemaritime-fnmm.com/stats/visits/record', {
      route,
      userAgent: navigator.userAgent,
      city,
      region,
      country: country_name,
      date: today
    });
  } catch (error) {
    console.error('Erreur lors de l\'enregistrement de la visite:', error);
  }
}; 