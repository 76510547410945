<script>
import DecContent from "@/components/Backoffice/Decorations/content/DecContent.vue";

export default {
  name: "DecorationsVue",
  components: {DecContent}
}
</script>

<template>
  <DecContent></DecContent>
</template>

<style scoped>

</style>