<template>
  <button class="py-2 px-4 bg-gray-900 hover:bg-gray-700 text-gray-200 rounded-xl mb-4" @click="$emit('return-to-sections')">Retour</button>
  <div class="pb-24 sm:pb-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl text-center">
        <h2 class="text-balance text-4xl font-semibold tracking-tight text-gray-200 sm:text-5xl">Articles {{ sectionTitle }}</h2>
        <div class="col-span-full">
          <div class="mt-4">
            <input type="text" name="search" id="search" placeholder="Chercher un article" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
          </div>
        </div>
      </div>

      <!-- Composants de création et modification d'articles -->
      <!-- Affichage du composant ViewArticle si showAddArticle est true -->
      <ViewArticle v-if="showAddArticle" @close="showAddArticle = false" />

      <ViewArticleEdit v-if="showEditArticle && selectedPost" :post="selectedPost" @close="showEditArticle = false" />

      <div class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-2">
        <button type="button" @click="showAddArticle = true" class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">
          <svg class="mx-auto size-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 4h24l8 8v32H6V4z" />
            <line x1="12" y1="20" x2="32" y2="20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line>
            <line x1="12" y1="26" x2="28" y2="26" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line>
            <line x1="12" y1="32" x2="24" y2="32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line>
          </svg>
          <span class="mt-2 block text-sm font-semibold text-white">Ajouter un article</span>
        </button>

        <!-- Affichage des articles -->
        <article v-for="post in posts" :key="post.id" class="relative flex flex-col items-start justify-between bg-gray-900 p-6 border-gray-700 border rounded-xl hover:bg-gray-700 shadow cursor-pointer">
  <div class="relative w-full" @click="editPost(post)">
    <img :src="post.img" alt="" class="aspect-video w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]" />
    <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
  </div>

  <div class="max-w-xl">
    <div class="mt-8 flex items-center gap-x-4 text-xs">
      <time :datetime="post.datetime" class="text-gray-300">{{ post.date }}</time>
      <span v-if="post.features === 1" class="inline-flex items-center rounded-md bg-green-400/10 px-2 py-1 text-xs font-medium text-green-400 ring-1 ring-inset ring-green-400/20">
        Article épinglé
      </span>
    </div>
    <div class="group relative">
      <h3 class="mt-3 text-lg/6 font-semibold text-gray-100">
        <a :href="post.href">
          <span class="absolute inset-0" />
          {{ post.title }}
        </a>
      </h3>
      <p class="mt-5 line-clamp-3 text-sm/6 text-gray-200">{{ post.description }}</p>
    </div>
  </div>

  <div class="mt-4 flex gap-2 w-full">
    <button
      @click.stop="togglePin(post)"
      class="flex-1 px-4 py-2 rounded-md text-white flex items-center justify-center gap-2"
      :class="post.features === 1 ? 'bg-green-600 hover:bg-green-700' : 'bg-gray-600 hover:bg-gray-700'"
    >
      <svg xmlns="http://www.w3.org/2000/svg" class="size-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
        <path d="M12 2L12 22" stroke-linecap="round"/>
        <path d="M5 12H19" stroke-linecap="round"/>
        <path d="M12 2C14.2091 2 16 3.79086 16 6V6C16 8.20914 14.2091 10 12 10V10C9.79086 10 8 8.20914 8 6V6C8 3.79086 9.79086 2 12 2Z" stroke-linecap="round"/>
      </svg>
      {{ post.features === 1 ? 'Désépingler' : 'Épingler' }}
    </button>

    <button 
      @click.stop="deletePost(post.id)" 
      class="flex-1 bg-red-600 hover:bg-red-500 text-white font-semibold py-2 px-4 rounded-lg transition flex items-center justify-center gap-2"
    >
      <svg xmlns="http://www.w3.org/2000/svg" class="size-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
      Supprimer
    </button>
  </div>
</article>

      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import ViewArticle from "@/components/Backoffice/GestionDesArticles/Sections/components/ViewArticle.vue";
import ViewArticleEdit from "@/components/Backoffice/GestionDesArticles/Sections/components/ViewArticleEdit.vue";

const sectionTitle = ref(localStorage.getItem('selectedSectionTitle') || 'Section actuelle');
const posts = ref([]);
const showAddArticle = ref(false);
const showEditArticle = ref(false);
const selectedPost = ref(null);

// Fonction pour épingler/désépingler un article
const togglePin = async (post) => {
  try {
    const response = await axios.put(`https://api.meritemaritime-fnmm.com/sections/update/features/${post.id}`);
    
    if (response.data) {
      // Mettre à jour l'état local de l'article
      post.features = response.data.features;
      // Rafraîchir la liste pour s'assurer que tout est à jour
      await fetchPosts();
    }
  } catch (error) {
    console.error('Erreur lors de la mise à jour du statut d\'épinglage:', error);
  }
};

const editPost = (post) => {
  localStorage.setItem('selectedPost', JSON.stringify(post));
  selectedPost.value = post;
  showEditArticle.value = true;
};

// Fonction pour récupérer les articles
const fetchPosts = async () => {
  const sectionId = localStorage.getItem('selectedSectionId');

  if (sectionId) {
    try {
      const response = await axios.get(`https://api.meritemaritime-fnmm.com/sections/showactualiter/${sectionId}`);
      // Trier les articles pour mettre les épinglés en premier
      const sortedPosts = response.data.sort((a, b) => b.features - a.features);
      posts.value = sortedPosts.map(post => ({
        id: post.id,
        title: post.title,
        description: post.description,
        img: post.img,
        date: post.date,
        datetime: post.datetime,
        pdf_url: post.pdf_url,
        features: post.features
      }));
    } catch (error) {
      console.error('Erreur lors de la récupération des articles:', error);
    }
  } else {
    console.log('Aucune section sélectionnée dans le localStorage');
  }
};

// Fonction pour supprimer un article
const deletePost = async (id) => {
  if (!confirm('Êtes-vous sûr de vouloir supprimer cet article ?')) {
    return;
  }

  try {
    await axios.delete('https://api.meritemaritime-fnmm.com/sections/article/delete', {
      data: { id },
      headers: { 'Content-Type': 'application/json' },
    });

    posts.value = posts.value.filter(post => post.id !== id);
  } catch (error) {
    console.error(`Erreur lors de la suppression de l'article ID: ${id}`, error);
  }
};

onMounted(() => {
  fetchPosts();
});
</script>
