<template>
  <div class="bg-neutral-50 py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <h2 class="max-w-2xl mb-20 text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">Actualités</h2>
      
      <div v-if="posts.length" class="mx-auto mb-16 max-w-2xl lg:mx-0 lg:max-w-none lg:grid lg:grid-cols-1">
        <div class="flex flex-col items-start justify-between">
          <div class="relative w-full">
            <img :src="posts[0].imageUrl" alt="" class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]" />
            <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
          </div>
          <div class="max-w-xl mt-8">
            <div class="flex items-center gap-x-4 text-xs">
              <time :datetime="posts[0].datetime" class="text-gray-500">{{ posts[0].date }}</time>
            </div>
            <div class="group relative">
              <h3 class="mt-3 text-2xl font-semibold text-gray-900 group-hover:text-gray-600">
                {{ posts[0].title }}
              </h3>
              <p class="mt-5 text-base text-gray-600">{{ posts[0].description }}</p>
              <div v-if="posts[0].pdf_url" class="mt-5 flex gap-4">
                <a 
                  :href="posts[0].pdf_url" 
                  target="_blank" 
                  class="inline-flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700"
                  @click.stop
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"/><polyline points="14 2 14 8 20 8"/><line x1="16" y1="13" x2="8" y2="13"/><line x1="16" y1="17" x2="8" y2="17"/><line x1="10" y1="9" x2="8" y2="9"/></svg>
                  Voir le PDF
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div v-if="posts.length > 1" class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <div v-for="post in posts.slice(1)" :key="post.id" class="flex flex-col items-start justify-between">
          <div class="relative w-full">
            <img :src="post.imageUrl" alt="" class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]" />
            <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
          </div>
          <div class="max-w-xl">
            <div class="mt-8 flex items-center gap-x-4 text-xs">
              <time :datetime="post.datetime" class="text-gray-500">{{ post.date }}</time>
            </div>
            <div class="group relative">
              <h3 class="mt-3 text-lg font-semibold text-gray-900 group-hover:text-gray-600">
                {{ post.title }}
              </h3>
              <p 
                class="mt-5 text-sm text-gray-600"
                :class="{ 'line-clamp-3': !expandedPosts.has(post.id) }"
              >
                {{ post.description }}
              </p>
              <button 
                v-if="isTextTruncated(post.description)"
                @click="toggleDescription(post.id)"
                class="mt-2 text-sm text-blue-600 hover:text-blue-800 font-medium"
              >
                {{ expandedPosts.has(post.id) ? 'Voir moins' : 'Voir plus' }}
              </button>
              <div v-if="post.pdf_url" class="mt-5 flex gap-4">
                <a 
                  :href="post.pdf_url" 
                  target="_blank" 
                  class="inline-flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700"
                  @click.stop
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"/><polyline points="14 2 14 8 20 8"/><line x1="16" y1="13" x2="8" y2="13"/><line x1="16" y1="17" x2="8" y2="17"/><line x1="10" y1="9" x2="8" y2="9"/></svg>
                  Voir le PDF
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';

const posts = ref([]);
const route = useRoute();
const expandedPosts = ref(new Set());

const isTextTruncated = (text) => {
  // On considère qu'un texte est tronqué s'il dépasse 150 caractères
  return text.length > 150;
};

const toggleDescription = (postId) => {
  if (expandedPosts.value.has(postId)) {
    expandedPosts.value.delete(postId);
  } else {
    expandedPosts.value.add(postId);
  }
};

onMounted(async () => {
  const id_section = route.params.id; 
  try {
    const response = await axios.get(`https://api.meritemaritime-fnmm.com/sections/showactualiter/${id_section}`);
    if (response.data.length > 0) {
      // Vérifier si tous les articles ont features: 0
      const allFeaturesZero = response.data.every(article => article.features === 0);
      
      let sortedArticles;
      if (allFeaturesZero) {
        // Si tous les articles ont features: 0, on met le dernier article en premier
        sortedArticles = [...response.data].reverse();
      } else {
        // Sinon, on trie par features
        sortedArticles = response.data.sort((a, b) => b.features - a.features);
      }
      
      posts.value = sortedArticles.map(article => ({
        id: article.id,
        title: article.title,
        description: article.description,
        imageUrl: article.img,
        pdf_url: article.pdf_url,
        date: new Date().toLocaleDateString(),
        datetime: new Date().toISOString()
      }));
    }
  } catch (error) {
    console.error("Erreur lors de la récupération des actualités", error);
  }
});
</script>
