<template>
  <div class="bg-white pb-24">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
        <article v-for="post in posts" :key="post.id_prix" class="flex flex-col items-start justify-between">
          <div class="relative w-full">
            <img
              v-if="post.img"
              :src="post.img"
              alt=""
              class="w-full rounded-2xl bg-gray-100 object-cover"
            />
            <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
          </div>
          <h2 v-if="post.nom" class="mt-4 text-xl font-bold">{{ post.nom }}</h2>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router'; // Assurez-vous que le routeur est configuré

const posts = ref([]); // Initialiser les posts
const route = useRoute(); // Accéder aux paramètres de la route

onMounted(async () => {
  const { id } = route.params; // Récupérer l'id de l'URL
  try {
    const response = await fetch(`https://api.meritemaritime-fnmm.com/prix/listpriximg/${id}`);
    if (response.ok) {
      const data = await response.json();
      posts.value = data; // Mettre à jour les posts avec les données reçues
    } else {
      console.error('Erreur lors de la récupération des données');
    }
  } catch (error) {
    console.error('Erreur réseau:', error);
  }
});
</script>
