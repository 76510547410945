<template>
  <div class="bg-[#072653] py-24">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <h2 class="max-w-2xl text-balance text-4xl font-semibold tracking-tight text-white sm:text-5xl">Liens Institutionnels</h2>
      <div class="mt-20 flex-col p-6 rounded gap-12 flex">
        <Popover>
          <PopoverButton class="flex items-center gap-x-1">
            <h3 class="text-balance text-2xl font-semibold tracking-tight text-white sm:text-3xl">Les Ordres Nationaux</h3>
            <ChevronDownIcon class="size-5 flex-none text-gray-400" aria-hidden="true" />
          </PopoverButton>
          <span class="max-w-2xl text-balance text-xl font-semibold tracking-tight text-white sm:text-xl text-start">GRANDE CHANCELLERIE DE LA LEGION D'HONNEUR</span>
          <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 -translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 -translate-y-1">
            <PopoverPanel class="inset-x-0 top-0 -z-10 lg:pt-4 pt-24">
              <div class="-mt-16  max-w-sm  divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:divide-x lg:divide-y-0 xl:-mx-4">
                <div v-for="ordre in ordre" :key="ordre.id" class="flex items-center lg:px-8 lg:pt-0 xl:px-8 text-white">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-dot"><circle cx="12.1" cy="12.1" r="1"/></svg>
                  <a :href="ordre.href" :aria-describedby="ordre.id" :id="ordre.id" target="_blank" class="text-xl flex items-center gap-2 text-white font-semibold hover:text-indigo-500">
                    {{ ordre.name }}
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-square-arrow-out-up-right"><path d="M21 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h6"/><path d="m21 3-9 9"/><path d="M15 3h6v6"/></svg>
                  </a>
                </div>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>

        <Popover>
          <PopoverButton class="flex items-center gap-x-1">
            <h3 class=" text-balance text-2xl font-semibold tracking-tight text-white sm:text-3xl">IGAM</h3>
            <ChevronDownIcon class="size-5 flex-none text-gray-400" aria-hidden="true" />
          </PopoverButton>
          <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 -translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 -translate-y-1">
            <PopoverPanel class="inset-x-0 top-0 -z-10 lg:pt-4 pt-24">
              <div class="-mt-16  max-w-sm  divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:divide-x lg:divide-y-0 xl:-mx-4">
                <div v-for="igam in igam" :key="igam.id" class="flex items-center lg:px-8 lg:pt-0 xl:px-8 text-white">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-dot"><circle cx="12.1" cy="12.1" r="1"/></svg>
                  <a :href="igam.href" :aria-describedby="igam.id" :id="igam.id" target="_blank" class="text-xl flex items-center gap-2 text-white font-semibold hover:text-indigo-500">
                    {{ igam.name }}
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-square-arrow-out-up-right"><path d="M21 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h6"/><path d="m21 3-9 9"/><path d="M15 3h6v6"/></svg>
                  </a>
                </div>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>

        <Popover>
          <PopoverButton class="flex items-center gap-x-1">
            <h3 class=" text-balance text-2xl font-semibold tracking-tight text-white sm:text-3xl text-start">Direction Générale des Affaires Maritimes de la Pêche et de l' Aquaculture</h3>
            <ChevronDownIcon class="size-5 flex-none text-gray-400" aria-hidden="true" />
          </PopoverButton>
          <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 -translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 -translate-y-1">
            <PopoverPanel class="inset-x-0 top-0 -z-10 lg:pt-4 pt-24">
              <div class="-mt-16  max-w-sm  divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:divide-x lg:divide-y-0 xl:-mx-4">
                <div v-for="dgampa in dgampa" :key="dgampa.id" class="flex items-center lg:px-8 lg:pt-0 xl:px-8 text-white">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-dot"><circle cx="12.1" cy="12.1" r="1"/></svg>
                  <a :href="dgampa.href" :aria-describedby="dgampa.id" :id="dgampa.id" target="_blank" class="text-xl flex items-center gap-2 text-white font-semibold hover:text-indigo-500">
                    {{ dgampa.name }}
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-square-arrow-out-up-right"><path d="M21 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h6"/><path d="m21 3-9 9"/><path d="M15 3h6v6"/></svg>
                  </a>
                </div>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>

        <Popover>
          <PopoverButton class="flex items-center gap-x-1">
            <h3 class=" text-balance text-2xl font-semibold tracking-tight text-white sm:text-3xl text-start">Les Préfectures Maritimes et Services de l'Etat en DOM-TOM</h3>
            <ChevronDownIcon class="size-5 flex-none text-gray-400" aria-hidden="true" />
          </PopoverButton>
          <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 -translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 -translate-y-1">
            <PopoverPanel class="inset-x-0 top-0 -z-10 lg:pt-4 pt-24">
              <div class="-mt-16  max-w-sm  divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:divide-x lg:divide-y-0 xl:-mx-4">
                <div v-for="prefectures in prefectures" :key="prefectures.id" class="flex items-center lg:px-8 lg:pt-0 xl:px-8 text-white">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-dot"><circle cx="12.1" cy="12.1" r="1"/></svg>
                  <a :href="prefectures.href" :aria-describedby="prefectures.id" :id="prefectures.id" target="_blank" class="text-xl flex items-center gap-2 text-white font-semibold hover:text-indigo-500">
                    {{ prefectures.name }}
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-square-arrow-out-up-right"><path d="M21 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h6"/><path d="m21 3-9 9"/><path d="M15 3h6v6"/></svg>
                  </a>
                </div>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>

        <Popover>
          <PopoverButton class="flex items-center gap-x-1">
            <h3 class=" text-balance text-2xl font-semibold tracking-tight text-white sm:text-3xl">Secrétariat d'Etat à la Mer</h3>
            <ChevronDownIcon class="size-5 flex-none text-gray-400" aria-hidden="true" />
          </PopoverButton>
          <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 -translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 -translate-y-1">
            <PopoverPanel class="inset-x-0 top-0 -z-10 lg:pt-4 pt-24">
              <div class="-mt-16  max-w-sm  divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:divide-x lg:divide-y-0 xl:-mx-4">
                <div v-for="secretariat in secretariat" :key="secretariat.id" class="flex items-center lg:px-8 lg:pt-0 xl:px-8 text-white">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-dot"><circle cx="12.1" cy="12.1" r="1"/></svg>
                  <a :href="secretariat.href" :aria-describedby="secretariat.id" :id="secretariat.id" target="_blank" class="text-xl flex items-center gap-2 text-white font-semibold hover:text-indigo-500">
                    {{ secretariat.name }}
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-square-arrow-out-up-right"><path d="M21 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h6"/><path d="m21 3-9 9"/><path d="M15 3h6v6"/></svg>
                  </a>
                </div>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>
      </div>
    </div>
  </div>
</template>

<script setup>

import {ChevronDownIcon} from "@heroicons/vue/20/solid";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
} from '@headlessui/vue'

const ordre = [
  {
    name: 'L\'ordre de la region d\'honneur',
    id: 'chancellerie',
    href: 'https://www.legiondhonneur.fr/fr',
  },
  {
    name: 'L\'Ordre National du Mérite',
    id: 'national',
    href: 'https://www.anmonm.org/',
  },
]

const igam = [
  {
    name: 'Inspection Générale des Affaire Maritimes',
    id: 'inspection',
    href: 'https://www.igam.developpement-durable.gouv.fr/spip.php?page=sommaire',
  },
]

const dgampa = [
  {
    name: 'Direction générale des affaires maritimes',
    id: 'direction',
    href: 'https://lannuaire.service-public.fr/gouvernement/b7b3f127-bc9d-47de-8094-cb38525dcfb8',
  },
]

const prefectures = [
  {
    name: 'PREMAR',
    id: 'prema',
    href: 'https://www.premar-manche.gouv.fr/',
  },
  {
    name: 'Préfet de la Martinique',
    id: 'martinique',
    href: 'https://www.martinique.gouv.fr/',
  },
  {
    name: 'Préfet de la région Guyane',
    id: 'guyane',
    href: 'https://www.guyane.gouv.fr/',
  },
  {
    name: 'Préfet de Saint-Barthélemy',
    id: 'barthelemy',
    href: 'http://www.saint-barth-saint-martin.gouv.fr/',
  },
  {
    name: 'Préfet de Saint-Martin',
    id: 'martin',
    href: 'http://www.saint-barth-saint-martin.gouv.fr/',
  },
  {
    name: 'Préfet de Saint-Pierre et Miquelon',
    id: 'pierre',
    href: 'http://www.saint-pierre-et-miquelon.gouv.fr/',
  },
  {
    name: 'Préfet Maritime de l\'Atlantique',
    id: 'atlantique',
    href: 'https://www.premar-atlantique.gouv.fr/',
  },
  {
    name: 'Préfet de la Réunion',
    id: 'reunion',
    href: 'http://www.reunion.gouv.fr/',
  },
  {
    name: 'Préfet de la Mayotte',
    id: 'mayotte',
    href: 'http://www.mayotte.gouv.fr/',
  },
  {
    name: 'TAAF - Terres Australes',
    id: 'australes',
    href: 'https://taaf.fr/collectivites/presentation-de-la-collectivite/',
  },
  {
    name: 'Ht-Comm. de la République de Nelle-Calédonie',
    id: 'nelle',
    href: 'http://www.nouvelle-caledonie.gouv.fr/',
  },
  {
    name: 'Ht-Comm. de la République en Polynésie Française',
    id: 'polynesie',
    href: 'http://www.polynesie-francaise.pref.gouv.fr/',
  },
  {
    name: 'Préfet des îles Wallis et Futuna',
    id: 'wallis',
    href: 'http://www.wallis-et-futuna.gouv.fr/',
  },
  {
    name: 'Préfet Maritime de la Méditerranée',
    id: 'mediterranee',
    href: 'https://www.premar-mediterranee.gouv.fr/',
  },
]

const secretariat = [
  {
    name: 'Secrétariat d\'Etat à la Mer\n',
    id: 'secretariat',
    href: 'https://mer.gouv.fr/',
  },
]
</script>