<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { ChevronUpDownIcon } from '@heroicons/vue/16/solid'
import { CheckIcon, TrashIcon } from '@heroicons/vue/20/solid'
import axios from 'axios'

// State variables
const sections = ref([])  // To store the list of sections fetched from API
const selectedSection = ref(null)
const sectionName = ref('')
const sectionDescription = ref('')
const sectionUrl = ref('')
const selectedMember = ref(null)

const newSectionName = ref('')
const newSectionDescription = ref('')
const newSectionUrl = ref('')


const members = ref([])  // To store the list of members fetched from the selected section

const filteredMembers = computed(() => {
  return members.value.filter(member => member.id_section === selectedSection.value?.id)
})

// Control whether the create section form is shown
const showCreateSectionForm = ref(false)

// Refs pour le formulaire d'édition
const memberName = ref('')
const memberRole = ref('')
const memberImageUrl = ref('')

// Nouvelles refs pour le formulaire de création
const newMemberName = ref('')
const newMemberRole = ref('')
const newMemberImageUrl = ref('')

// Ajouter une ref pour contrôler l'affichage du formulaire de création
const showCreateMemberForm = ref(false)

// Fetch sections from API on component mount
onMounted(async () => {
  try {
    const response = await axios.get('https://api.meritemaritime-fnmm.com/sections/showlist')
    sections.value = response.data || [] // Assuming the response is an array of sections
    selectedSection.value = sections.value[0] // Select the first section by default
  } catch (error) {
    console.error('Error fetching sections:', error)
  }
})

watch(selectedSection, async (newSection) => {
  if (newSection) {
    // Set form fields based on the selected section
    sectionName.value = newSection.nom
    sectionDescription.value = newSection.description
    sectionUrl.value = newSection.link

    // Fetch members of the selected section
    try {
      const response = await axios.get(`https://api.meritemaritime-fnmm.com/sections/showmember/${newSection.id}`)
      // Trier les membres par position
      members.value = response.data.sort((a, b) => (a.position || 0) - (b.position || 0))

      // Set the first member as the selected member
      if (members.value.length > 0) {
        selectedMember.value = members.value[0]
      }

    } catch (error) {
      console.error('Error fetching members:', error)
    }
  }
})

watch(selectedMember, (newMember) => {
  if (newMember) {
    // Combiner prénom et nom pour le champ nom complet
    memberName.value = `${newMember.prenom} ${newMember.nom}`
    memberRole.value = newMember.role || ''
    memberImageUrl.value = newMember.img || ''
  } else {
    // Réinitialiser les champs si aucun membre n'est sélectionné
    memberName.value = ''
    memberRole.value = ''
    memberImageUrl.value = ''
  }
})

const createSection = async () => {
  if (newSectionName.value && newSectionDescription.value && newSectionUrl.value) {
    try {
      const response = await axios.post('https://api.meritemaritime-fnmm.com/sections/addsection', {
        nom: newSectionName.value,
        description: newSectionDescription.value,
        link: newSectionUrl.value
      })
      
      // Rafraîchir la liste des sections
      const sectionsResponse = await axios.get('https://api.meritemaritime-fnmm.com/sections/showlist')
      sections.value = sectionsResponse.data || []
      
      // Sélectionner la nouvelle section
      selectedSection.value = sections.value.find(section => section.id === response.data.id)
      
      // Réinitialiser les champs et fermer le formulaire
      newSectionName.value = ''
      newSectionDescription.value = ''
      newSectionUrl.value = ''
      showCreateSectionForm.value = false
      
    } catch (error) {
      console.error('Erreur lors de la création de la section:', error)
    }
  }
}

// Function to update the member
const updateMember = async () => {
  if (selectedMember.value && selectedMember.value.id) {
    try {
      // Séparer le nom complet en prénom et nom
      const [prenom, ...nomParts] = memberName.value.split(' ')
      const nom = nomParts.join(' ')

      const response = await axios.put('https://api.meritemaritime-fnmm.com/members/updatemember', {
        id: selectedMember.value.id,
        prenom: prenom,
        nom: nom,
        role: memberRole.value,
        img: memberImageUrl.value,
      })

      // Rafraîchir la liste des membres
      const membersResponse = await axios.get(`https://api.meritemaritime-fnmm.com/sections/showmember/${selectedSection.value.id}`)
      members.value = membersResponse.data || []

      // Mettre à jour le membre sélectionné
      selectedMember.value = members.value.find(member => member.id === selectedMember.value.id)

      console.log('Membre mis à jour avec succès:', response.data)
    } catch (error) {
      console.error('Erreur lors de la mise à jour du membre:', error)
    }
  }
}

// Function to update the section
const updateSection = async () => {
  if (selectedSection.value) {
    try {
      const response = await axios.put(`https://api.meritemaritime-fnmm.com/sections/updatesection`, {
        id: selectedSection.value.id,
        nom: sectionName.value,
        description: sectionDescription.value,
        link: sectionUrl.value
      })
      console.log('Section updated successfully:', response.data)
    } catch (error) {
      console.error('Error updating section:', error)
    }
  }
}

// Ajouter cette nouvelle fonction pour la suppression
const deleteSection = async () => {
  if (selectedSection.value && selectedSection.value.id) {
    try {
      await axios.delete('https://api.meritemaritime-fnmm.com/sections/deletesectionitem', {
        data: { id: selectedSection.value.id }
      })
      
      // Rafraîchir la liste des sections
      const sectionsResponse = await axios.get('https://api.meritemaritime-fnmm.com/sections/showlist')
      sections.value = sectionsResponse.data || []
      
      // Sélectionner la première section de la liste mise à jour ou null si la liste est vide
      selectedSection.value = sections.value.length > 0 ? sections.value[0] : null
      
      // Réinitialiser les champs du formulaire
      sectionName.value = selectedSection.value?.nom || ''
      sectionDescription.value = selectedSection.value?.description || ''
      sectionUrl.value = selectedSection.value?.link || ''
      
    } catch (error) {
      console.error('Erreur lors de la suppression de la section:', error)
    }
  }
}

// Modifier la fonction createMember
const createMember = async () => {
  if (!selectedSection.value) {
    console.error('Aucune section sélectionnée');
    return;
  }

  try {
    const [prenom, ...nomParts] = newMemberName.value.split(' ')
    const nom = nomParts.join(' ')

    // Correction de l'URL de l'API
    await axios.post('https://api.meritemaritime-fnmm.com/members/addmember', {
      prenom: prenom,
      nom: nom,
      role: newMemberRole.value,
      img: newMemberImageUrl.value,
      id_section: selectedSection.value.id
    })

    // Rafraîchir la liste des membres
    const membersResponse = await axios.get(`https://api.meritemaritime-fnmm.com/sections/showmember/${selectedSection.value.id}`)
    members.value = membersResponse.data || []

    // Réinitialiser les champs et fermer le formulaire
    newMemberName.value = ''
    newMemberRole.value = ''
    newMemberImageUrl.value = ''
    showCreateMemberForm.value = false

    console.log('Membre créé avec succès')

  } catch (error) {
    console.error('Erreur lors de la création du membre:', error)
  }
}

// Ajouter la fonction deleteMember
const deleteMember = async () => {
  if (selectedMember.value && selectedMember.value.id) {
    try {
      await axios.delete('https://api.meritemaritime-fnmm.com/sections/deletemember', {
        data: { id: selectedMember.value.id }
      })

      // Rafraîchir la liste des membres
      const membersResponse = await axios.get(`https://api.meritemaritime-fnmm.com/sections/showmember/${selectedSection.value.id}`)
      members.value = membersResponse.data || []

      // Réinitialiser le membre sélectionné et les champs du formulaire
      selectedMember.value = members.value.length > 0 ? members.value[0] : null
      memberName.value = ''
      memberRole.value = ''
      memberImageUrl.value = ''

      console.log('Membre supprimé avec succès')
    } catch (error) {
      console.error('Erreur lors de la suppression du membre:', error)
    }
  }
}

// Ajouter les nouvelles méthodes pour la gestion des positions
const moveMemberUp = async (memberId) => {
  const memberIndex = members.value.findIndex(m => m.id === memberId)
  if (memberIndex > 0) {
    try {
      // Échanger les positions avec le membre précédent
      const currentMember = members.value[memberIndex]
      const previousMember = members.value[memberIndex - 1]
      
      // Mettre à jour les positions dans la base de données
      await axios.put(`https://api.meritemaritime-fnmm.com/sections/updateposition/${currentMember.id}`, {
        position: previousMember.position
      })
      await axios.put(`https://api.meritemaritime-fnmm.com/sections/updateposition/${previousMember.id}`, {
        position: currentMember.position
      })

      // Mettre à jour l'interface
      const temp = members.value[memberIndex]
      members.value[memberIndex] = members.value[memberIndex - 1]
      members.value[memberIndex - 1] = temp
    } catch (error) {
      console.error('Erreur lors du déplacement du membre:', error)
    }
  }
}

const moveMemberDown = async (memberId) => {
  const memberIndex = members.value.findIndex(m => m.id === memberId)
  if (memberIndex < members.value.length - 1) {
    try {
      // Échanger les positions avec le membre suivant
      const currentMember = members.value[memberIndex]
      const nextMember = members.value[memberIndex + 1]
      
      // Mettre à jour les positions dans la base de données
      await axios.put(`https://api.meritemaritime-fnmm.com/sections/updateposition/${currentMember.id}`, {
        position: nextMember.position
      })
      await axios.put(`https://api.meritemaritime-fnmm.com/sections/updateposition/${nextMember.id}`, {
        position: currentMember.position
      })

      // Mettre à jour l'interface
      const temp = members.value[memberIndex]
      members.value[memberIndex] = members.value[memberIndex + 1]
      members.value[memberIndex + 1] = temp
    } catch (error) {
      console.error('Erreur lors du déplacement du membre:', error)
    }
  }
}

// Nouvelle méthode pour déplacer un membre à une position spécifique
const moveMemberToPosition = async (memberId, targetPosition) => {
  try {
    const memberIndex = members.value.findIndex(m => m.id === memberId)
    const currentPosition = memberIndex + 1
    
    // Si la position cible est la même que la position actuelle, ne rien faire
    if (currentPosition === targetPosition) return
    
    // Créer une copie du tableau des membres
    let updatedMembers = [...members.value]
    const movingMember = updatedMembers[memberIndex]
    
    // Retirer le membre de sa position actuelle
    updatedMembers.splice(memberIndex, 1)
    
    // Insérer le membre à sa nouvelle position
    updatedMembers.splice(targetPosition - 1, 0, movingMember)
    
    // Mettre à jour les positions de tous les membres
    updatedMembers = updatedMembers.map((member, index) => ({
      ...member,
      position: index + 1
    }))
    
    // Mettre à jour l'interface avant l'appel API pour une réponse immédiate
    members.value = updatedMembers
    
    // Mettre à jour les positions dans la base de données
    const updatePromises = updatedMembers.map(member => {
      return axios.put(`https://api.meritemaritime-fnmm.com/sections/updateposition/${member.id}`, {
        position: member.position
      })
    })
    
    await Promise.all(updatePromises)
    
    // Recharger les membres depuis le serveur pour s'assurer de la synchronisation
    const response = await axios.get(`https://api.meritemaritime-fnmm.com/sections/showmember/${selectedSection.value.id}`)
    members.value = response.data.sort((a, b) => (a.position || 0) - (b.position || 0))
    
  } catch (error) {
    console.error('Erreur lors du déplacement du membre:', error)
    // En cas d'erreur, recharger les données pour rétablir l'état correct
    const response = await axios.get(`https://api.meritemaritime-fnmm.com/sections/showmember/${selectedSection.value.id}`)
    members.value = response.data.sort((a, b) => (a.position || 0) - (b.position || 0))
  }
}
</script>


<template>
<div class="bg-gray-900 p-6 rounded-xl shadow mt-20 border-gray-700 border">
  <!-- List of sections -->
  <Listbox as="div" v-model="selectedSection">
    <ListboxLabel class="block text-sm/6 font-medium text-gray-200">Sélectionner une section</ListboxLabel>
    <div class="relative mt-2">
      <ListboxButton class="grid w-full cursor-default grid-cols-1 rounded-md bg-gray-700 py-4 pl-3 pr-2 text-left text-gray-200 outline outline-1 -outline-offset-1 outline-gray-700 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6">
        <span class="col-start-1 row-start-1 truncate pr-6">{{ selectedSection?.nom || 'Sélectionner une section' }}</span>
        <ChevronUpDownIcon class="col-start-1 row-start-1 size-5 self-center justify-self-end text-gray-500 sm:size-4" aria-hidden="true" />
      </ListboxButton>

      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-700 py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
          <ListboxOption as="template" v-for="section in sections" :key="section.id" :value="section" v-slot="{ active, selected }">
            <li :class="[active ? 'bg-green-600 text-white outline-none ' : 'text-gray-200', 'relative cursor-default select-none py-2 pl-3 pr-9']">
              <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ section.nom }}</span>
              <span v-if="selected" :class="[active ? 'text-white' : 'text-green-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                <CheckIcon class="size-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>

  <button @click="showCreateSectionForm = !showCreateSectionForm" class="rounded-md mt-6 flex w-full justify-center bg-green-600 px-3 py-4 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
      Créer une section
    </button>
</div>

<div v-if="showCreateSectionForm" class="bg-gray-900 p-6 rounded-xl shadow mt-6 border-gray-700 border">
    <div class="flex gap-6 flex-col">
      <div>
        <label for="name" class="text-gray-200">Nom section</label>
        <input type="text" v-model="newSectionName" id="name" placeholder="Nom de la section" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
      <div>
        <label for="description" class="text-gray-200">Description</label>
        <textarea v-model="newSectionDescription" id="description" placeholder="Description de la section" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
      <div>
        <label for="url" class="text-gray-200">Lien du site</label>
        <input type="text" v-model="newSectionUrl" id="url" placeholder="Lien du site" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
    </div>

    <div class="mt-6 flex items-center justify-end gap-x-4">
      <button @click="createSection" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
        Enregistrer
      </button>
      <button @click="showCreateSectionForm = false" class="rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
        Annuler
      </button>
    </div>
  </div>

<div class="bg-gray-900 p-6 rounded-xl shadow mt-6 border-gray-700 border">
    <div class="flex gap-6 flex-col">
      <div>
        <label for="name" class="text-gray-200">Nom section</label>
        <input type="text" v-model="sectionName" id="name" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
      <div>
        <label for="name" class="text-gray-200">Description</label>
        <textarea v-model="sectionDescription" id="name" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
      <div>
        <label for="url" class="text-gray-200">Lien du site</label>
        <input type="text" v-model="sectionUrl" id="url" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
    </div>

    <div class="mt-6 flex items-center justify-end gap-x-4">
      <button @click="updateSection" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Enregistrer</button>
      <button @click="deleteSection" type="button" class="rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
        <TrashIcon class="w-5 h-5 shrink-0 text-gray-200 hover:text-red-400" aria-hidden="true"/>
      </button>
    </div>
  </div>

  <!-- Listbox for selecting member -->
  <div class="bg-gray-900 p-6 rounded-xl shadow mt-20 border-gray-700 border">
    <Listbox as="div" v-model="selectedMember">
      <ListboxLabel class="block text-sm/6 font-medium text-gray-200">Sélectionner un membre</ListboxLabel>
      <div class="relative mt-2">
        <ListboxButton class="grid w-full cursor-default grid-cols-1 rounded-md bg-gray-700 py-2 pl-3 pr-2 text-left text-gray-200 outline outline-1 -outline-offset-1 outline-gray-700 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6">
          <span class="col-start-1 row-start-1 flex items-center gap-3 pr-6">
            <img v-if="selectedMember?.img" :src="selectedMember.img" alt="" class="size-10 shrink-0 rounded-full object-cover" />
            <span class="block truncate">{{ selectedMember?.prenom }} {{ selectedMember?.nom }}</span>
          </span>
          <ChevronUpDownIcon class="col-start-1 row-start-1 size-5 self-center justify-self-end text-gray-500 sm:size-4" aria-hidden="true" />
        </ListboxButton>

        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
          <ListboxOptions class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-gray-700 py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            <ListboxOption as="template" v-for="member in filteredMembers" :key="member.idMember" :value="member" v-slot="{ active, selected }">
              <li :class="[active ? 'bg-green-600 text-white outline-none' : 'text-gray-200', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                <div class="flex items-center">
                  <img v-if="member.img" :src="member.img" alt="" class="size-10 shrink-0 rounded-full object-cover" />
                  <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">{{ member.prenom }} {{  member.nom }}</span>
                </div>
                <span v-if="selected" :class="[active ? 'text-white' : 'text-green-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                  <CheckIcon class="size-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>

    <div class="mt-6 flex items-center justify-end gap-x-4">
      <button 
        @click="showCreateMemberForm = !showCreateMemberForm" 
        type="button" 
        class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
      >
        Créer un membre
      </button>
    </div>
  </div>

  <!-- Modifier le formulaire de création pour utiliser les nouvelles refs -->
  <div v-if="showCreateMemberForm" class="bg-gray-900 p-6 rounded-xl shadow mt-6 border-gray-700 border">
    <div class="flex gap-6 flex-col">
      <div>
        <label for="createName" class="text-gray-200">Nom complet</label>
        <input 
          type="text" 
          v-model="newMemberName" 
          id="createName" 
          placeholder="Prénom Nom" 
          class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" 
        />
      </div>
      <div>
        <label for="createRole" class="text-gray-200">Role</label>
        <input 
          type="text" 
          v-model="newMemberRole" 
          id="createRole" 
          placeholder="Rôle du membre" 
          class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" 
        />
      </div>
      <div>
        <label for="createUrl" class="text-gray-200">URL image</label>
        <input 
          type="text" 
          v-model="newMemberImageUrl" 
          id="createUrl" 
          placeholder="URL de l'image" 
          class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" 
        />
      </div>
    </div>

    <div class="mt-6 flex items-center justify-end gap-x-4">
      <button 
        @click="createMember" 
        type="button" 
        class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
      >
        Enregistrer
      </button>
      <button 
        @click="showCreateMemberForm = false" 
        type="button" 
        class="rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
      >
        Annuler
      </button>
    </div>
  </div>

  <!-- Formulaire d'édition de membre -->
  <div v-if="selectedMember" class="bg-gray-900 p-6 rounded-xl shadow mt-6 border-gray-700 border">
    <div class="flex gap-6 flex-col">
      <div>
        <label for="editName" class="text-gray-200">Nom complet</label>
        <input 
          type="text" 
          v-model="memberName" 
          id="editName" 
          placeholder="-" 
          class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" 
        />
      </div>
      <div>
        <label for="editRole" class="text-gray-200">Role</label>
        <input 
          type="text" 
          v-model="memberRole" 
          id="editRole" 
          placeholder="-" 
          class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" 
        />
      </div>
      <div>
        <label for="editUrl" class="text-gray-200">URL image</label>
        <input 
          type="text" 
          v-model="memberImageUrl" 
          id="editUrl" 
          placeholder="-" 
          class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" 
        />
      </div>
    </div>

    <div class="mt-6 flex items-center justify-end gap-x-4">
      <button 
        @click="updateMember" 
        type="button" 
        class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
      >
        Mettre à jour
      </button>
      <button 
        @click="deleteMember"
        type="button" 
        class="rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
      >
        <TrashIcon class="w-5 h-5 shrink-0 text-gray-200 hover:text-red-400" aria-hidden="true"/>
      </button>
    </div>
  </div>

  <div class="space-y-4">
    <div v-for="member in members" :key="member.id" class="bg-gray-800 rounded-lg shadow-md p-4 flex items-center justify-between">
      <div class="flex items-center space-x-4">
        <img 
          :src="member.img" 
          :alt="`Photo de ${member.prenom} ${member.nom}`" 
          class="w-12 h-12 rounded-full object-cover"
        >
        <div>
          <h3 class="text-gray-200 font-medium">{{ member.prenom }} {{ member.nom }}</h3>
          <p class="text-gray-400 text-sm">{{ member.role }}</p>
        </div>
      </div>
      <div class="flex items-center gap-4">
        <span class="text-gray-400 text-sm">Position actuelle: {{ member.position || '-' }}</span>
        <select 
          :value="member.position"
          @change="(e) => moveMemberToPosition(member.id, parseInt(e.target.value))"
          class="bg-gray-700 text-gray-200 rounded-md border border-gray-600 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option v-for="n in members.length" :key="n" :value="n">
            Position {{ n }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>
