<template>
  <ul role="list" class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 mt-12 border-t pt-16 border-gray-700">
    <!-- Section de recherche -->
    <div class="col-span-full bg-gray-900 p-6 rounded-xl border-gray-700 border">
      <label for="search" class="block text-sm/6 font-medium text-white">Rechercher une image</label>
      <div class="mt-2">
        <input
          type="text"
          name="search"
          id="search"
          v-model="searchQuery"
          placeholder="Rechercher par titre..."
          class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6"
        />
      </div>
    </div>

    <!-- Affichage des images filtrées -->
    <li v-for="file in filteredFiles" :key="file.urlimage" class="relative bg-gray-900 p-6 rounded-xl shadow border-gray-700 border">
      <div class="group overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
        <img :src="file.urlimage" alt="" class="pointer-events-none aspect-[10/7] object-cover group-hover:opacity-75" />
      </div>
      <p class="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-200">{{ file.name }}</p>
      <div class="flex w-full justify-end gap-2">
        <button @click="deleteImage(file.id)" class="mt-4 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">Supprimer</button>
        <button @click="copyToClipboard(file.urlimage)" class="mt-4 rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Copier URL</button>
      </div>
    </li>
  </ul>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';

const files = ref([]);
const searchQuery = ref('');

// Fonction pour récupérer les images depuis l'API
const fetchImages = async () => {
  try {
    const response = await fetch('https://api.meritemaritime-fnmm.com/galerie/image');
    const data = await response.json();
    console.log('Images reçues:', data); // Pour vérifier les données
    files.value = data.map(file => ({
      ...file,
      id: file.id // S'assurer que l'ID est bien présent
    }));
  } catch (error) {
    console.error('Erreur lors de la récupération des images:', error);
  }
};

// Fonction pour copier l'URL dans le presse-papier
const copyToClipboard = (url) => {
  navigator.clipboard.writeText(url)
    .then(() => {
      alert('URL copiée dans le presse-papier');
    })
    .catch((err) => {
      console.error('Erreur lors de la copie:', err);
    });
};

// Filtrage des fichiers en fonction de la recherche
const filteredFiles = computed(() => {
  return files.value.filter(file =>
    file.name.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});

// Récupérer les images lorsque le composant est monté
onMounted(() => {
  fetchImages();
});

const deleteImage = async (id) => {
  console.log('ID à supprimer:', id); // Pour vérifier l'ID
  if (!confirm('Êtes-vous sûr de vouloir supprimer cette image ?')) return;
  
  try {
    const response = await fetch(`https://api.meritemaritime-fnmm.com/galerie/image/delete/${id}`, {
      method: 'DELETE'
    });
    
    if (response.ok) {
      files.value = files.value.filter(file => file.id !== id);
      alert('Image supprimée avec succès');
    } else {
      throw new Error('Erreur lors de la suppression');
    }
  } catch (error) {
    console.error('Erreur lors de la suppression de l\'image:', error);
    alert('Une erreur est survenue lors de la suppression de l\'image');
  }
};
</script>

<style scoped>
/* Tu peux ajouter ici des styles si nécessaire */
</style>
